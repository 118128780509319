<template>
    <div>
        <van-nav-bar title="按周填报" left-text="返回" @click-left="back" fixed left-arrow style="z-index:2"/>

        <van-form class="login_form">
            <van-grid :column-num="2" :border="false">
                <van-grid-item :text="dateText[0] + ' 至 ' + dateText[1]"></van-grid-item>
                <van-grid-item>
                    <div style="width:100%">
                    <van-button @click="SwitchLastorNext(1)" size="small" style="float:left;width:50%">上周</van-button>
                    <van-button @click="SwitchLastorNext(-1)" :disabled="weekSwitchIndex < 2 && user.timeType.fillAhead == 0" size="small" style="float:left;width:50%">下周</van-button>
                    </div>
                </van-grid-item>
            </van-grid>
            <van-grid :column-num="weekIndex == 7 ? weekIndex : weekIndex + 1" :border="false" style="position:relative">
                <van-grid-item v-for="item,index in dateRange" :key="index" :class="index == inbtn ? 'inbtn' : ''">
                    <van-button
                    class="selectgxbtn"
                    type="default"
                    size="mini"
                    @click="switchWeek(item,index)"
                    :disabled="canSelect(item)" v-if="user.timeType.enableNewWeeklyfill != 1">{{weekArr[index]}}</van-button>
                    <van-button
                    class="selectgxbtn"
                    type="default"
                    size="mini"
                    @click="switchWeek(item,index)"
                    :disabled="canSelect(item)" v-if="user.timeType.enableNewWeeklyfill == 1">{{ weekText(dateRange[index]) }}</van-button>
                </van-grid-item>
                <!-- 周总结 -->
                <van-grid-item v-if="user.timeType.enableNewWeeklyfill == 1" :class="999 == inbtn ? 'inbtn' : ''">
                    <van-button class="selectgxbtn"
                    type="default"
                    size="mini" @click="switchWeekly(999)">周总结</van-button>
                </van-grid-item>
                <van-grid-item v-if="weekIndex != 7 && user.timeType.enableNewWeeklyfill != 1">
                    <van-button type="default" size="mini" class="selectgxbtn" @click="addWeekIndex()" icon="plus"></van-button>
                </van-grid-item>
                <div class="kaoqin2" v-if="kaoqinText"><span>当日考勤：</span><span>{{kaoqinText}}</span></div>
                <div class="kaoqin" v-if="workTimeText"><span>已填报工时：</span><span>{{workTimeText}}小时</span></div>
                <div class="submitClear">
                    <van-button type="default" size="small" @click="submitClear">清空</van-button>
                </div>
            </van-grid>
            <van-cell title="总时长(h)" v-if="reportTimeType.type == 3">
                <template>
                    <van-stepper style="float:left;" :disabled="user.timeType.lockWorktime == 1" v-model="reportTimeType.allday" @change="changeAllTime" min="0.5" max="12" step="0.5" button-size="24px" :decimal-length="1" />
                </template>
            </van-cell>

            <!-- 每一周的数据循环 -->
            <div v-if="inbtn != 999">
                <div class="form_domains" v-for="(item,index) in currentForm.domains" :key="item.id">
                    <div style="float:right;margin-top:10px;margin-right:10px;">
                    
                    <van-tag v-if="index > 0 && item.state > 1" color="#fff" 
                    @click="delPro(index)" style="border: 1px solid #ff0000;padding:5px;margin-left:10px;"
                    icon="plus" type="default" ><span style="color:#666;padding: 0 5px;">删除</span></van-tag>
                    </div>

                    <!-- 当前的状态 -->
                    <div class="currentState" :style="`color: ${['#E6A23C', '#5CB87A'][item.state] || '#000'}`">
                        {{ ['待审核', '已通过'][item.state] || '' }}
                    </div>

                    <van-cell-group :title="(user.companyId==781?'任务':'项目') + (index+1)">

                        <!-- <div>请选择投入项目</div> -->
                        <!--按周填报的项目改成非必填-->
                        <van-field  readonly  name="projectId" clickable :value="item.projectName" :label="user.companyId==781?'工作任务':'投入项目'" :placeholder="user.companyId==781?'请选择工作任务':'请选择项目'" 
                            @click="clickPicker(index, item)" :disabled="item.state<=1" type="textarea" autosize rows="1" />
                        <van-field  readonly  name="subProjectId" v-if="item.subProjectList != null && item.subProjectList.length > 0 && user.timeType.mainProjectState != 1" clickable 
                            :value="item.subProjectName" label="子项目" placeholder="请选择子项目"  :disabled="item.state<=1"
                        @click="clickPickSubProject(index, item)" />

                        <van-popup v-model="item.showPickerSubProject" position="bottom">
                            <van-picker show-toolbar :columns="item.subProjectList" value-key="name" @confirm="choseSubProject" 
                                @cancel="item.showPickerSubProject = false;$forceUpdate();" />
                        </van-popup>
                        <!--任务分组 -->
                        <van-field  readonly  name="groupId" v-if="user.company.packageProject==1&&item.taskGroups != null && item.taskGroups.length > 0 && (user.company.nonProjectSimple==0 || (user.company.nonProjectSimple==1 && (item.projectId && projectss.filter(p => p.id == item.projectId)[0].isPublic!=1)))" clickable 
                            :value="item.groupName" :label="user.timeType.enableNewWeeklyfill == 1 ? '分组' : '任务分组'" :placeholder="user.timeType.enableNewWeeklyfill == 1 ? '请选择分组' : '请选择任务分组'" 
                        @click="clickPickTaskGroup(index, item)" :disabled="item.state<=1" />
                        <!-- <van-popup v-model="item.showPickerTaskGroup" position="bottom">
                            <van-picker show-toolbar :columns="item.taskGroups" value-key="name" @confirm="choseTaskGroup" 
                                @cancel="item.showPickerTaskGroup = false;$forceUpdate();" />
                        </van-popup> -->
                        <van-popup v-model="item.showPickerTaskGroup" position="bottom" :style="{ height: '80vh' }" @click-overlay="item.showPickerTaskGroup = false;$forceUpdate();">
                            <div class="groupingSelection">
                                <div v-for="(taskItem, taskIndex) in item.taskGroups" :key="taskIndex" class="groupingSelectionItem" @click="choseTaskGroup(taskItem, taskIndex)">
                                    {{ taskItem.name }}
                                </div>
                            </div>
                        </van-popup>
                        <!--任务阶段 -->
                        <van-field  readonly  name="stage" v-if="user.company.packageProject==1 && !user.timeType.hideStages &&item.stages != null && item.stages.length > 0 && user.timeType.enableNewWeeklyfill != 1" clickable 
                            :value="item.stage" label="投入阶段" placeholder="请选择投入阶段"  :disabled="item.state<=1"
                        @click="clickPickStage(index, item)" />
                        <van-popup v-model="item.showPickerStage" position="bottom">
                            <van-picker show-toolbar :columns="item.stages" value-key="stagesName" @confirm="choseStage" 
                                @cancel="item.showPickerStage = false;$forceUpdate();" />
                        </van-popup>
                        <!-- 预算来源 -->
                        <van-field  readonly  name="basecostId" v-if="user.company.packageProject==1&&reportBasecostList &&reportBasecostList.length>0" 
                            :value="item.basecostName" label="预算来源" placeholder="请选择预算来源" :disabled="item.state<=1"
                        @click="clickPickCostId(index, item)" />
                        <van-popup v-model="item.showPickerCostId" position="bottom">
                            <van-picker show-toolbar :columns="reportBasecostList" value-key="name" @confirm="choseCostId" 
                                @cancel="item.showPickerCostId = false;$forceUpdate();" />
                        </van-popup>
                        <!-- 审核人 -->
                    <template v-if="user.timeType.reportAuditType != 3">
                        <van-field  readonly  name="projectAuditorId" v-if="item.auditUserList != null && item.auditUserList.length > 0" clickable
                            :value="item.projectAuditorName" :label="user.companyId==781?'审核人':'项目审核人'" placeholder="请选择审核人"  :disabled="item.state<=1"
                        @click="clickPickAuditor(index, item)">
                            <template #input>
                                <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.projectAuditorName'></TranslationOpenDataText></span>
                                <span v-else>{{item.projectAuditorName}}</span>
                            </template>
                        </van-field>
                        <van-popup v-model="item.showPickerAuditor" position="bottom">
                            <van-picker show-toolbar :columns="item.auditUserList" value-key="auditorName" @confirm="choseAuditor" 
                            @cancel="item.showPickerAuditor = false;$forceUpdate();">
                                <template #option="item">
                                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.auditorName'></TranslationOpenDataText></span>
                                    <span v-else>{{item.auditorName}}</span>
                                </template>
                            </van-picker>
                        </van-popup>
                    </template>


                        <!-- 自选审批人 -->
                    <template v-if="user.timeType.reportAuditType == 3">
                        <van-field readonly clickable label="第一审核人" @click="auditorClick(index,'auditorFirst')">
                            <template #input>
                                <span v-if="!item.auditorFirst"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.auditorFirst.name'></TranslationOpenDataText></span>
                                <span v-else>{{item.auditorFirst.name}}</span>
                            </template>
                        </van-field>
                        <van-field readonly clickable label="第二审核人" @click="auditorClick(index,'auditorSec')" v-if="user.timeType.auditLevel > 1">
                            <template #input>
                                <span v-if="!item.auditorSec"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.auditorSec.name'></TranslationOpenDataText></span>
                                <span v-else>{{item.auditorSec.name}}</span>
                            </template>
                        </van-field>
                        <van-field readonly clickable label="第三审核人" @click="auditorClick(index,'auditorThird')" v-if="user.timeType.auditLevel > 2">
                            <template #input>
                                <span v-if="!item.auditorThird"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.auditorThird.name'></TranslationOpenDataText></span>
                                <span v-else>{{item.auditorThird.name}}</span>
                            </template>
                        </van-field>
                        <van-field readonly clickable label="抄送人" @click="auditorClick(index,'ccUserid')">
                            <template #input>
                                <span v-if="!item.ccUserid"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.ccUserid.name'></TranslationOpenDataText></span>
                                <span v-else>{{item.ccUserid.name}}</span>
                            </template>
                        </van-field>
                        <van-popup v-model="auditorShow" position="bottom">
                            <van-search v-model="auditor.searchText" placeholder="输入员工姓名搜索" @search="onSearch" v-if="user.userNameNeedTranslate != '1'"></van-search>
                            <div style="minHeight:300px;">
                                <van-radio-group v-model="auditor.item">
                                    <van-radio v-for="uitem in auditor.searchList" :key="uitem.id" :name="uitem" style="padding:10px">
                                        <span>{{uitem.name}}</span>
                                    </van-radio>
                                </van-radio-group>
                                <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="searchAuditor()">确定</van-button>
                            </div>
                        </van-popup>
                    </template>


                        <!-- 相关维度 -->
                        <van-field :value="item.weiduName" v-if="item.projectId && user.timeType.customDegreeActive == 1" :disabled="item.state<=1"  readonly  name="id" clickable :label="user.timeType.customDegreeName" placeholder="请选择" @click="clickPickers(index, item)"/>
                        <!-- <van-popup v-model="item.showPickDegree" position="bottom">
                            <van-picker show-toolbar :columns="item.wuduList" value-key="name" @confirm="choseProjects" @cancel="item.showPickDegree = false;$forceUpdate()" />
                        </van-popup> -->

                        <van-popup v-model="item.showPickDegree" position="bottom" @click-overlay="overlayPopup(index, 'showPickDegree')">
                            <div class="chooseSomeone">
                                <div class="chooseSomeoneFlex1">
                                    <!-- 复选 -->
                                    <van-radio-group v-model="item.radioPickDegree" v-if="!user.timeType.customDegreeMultiple" @change="$forceUpdate()">
                                        <van-radio v-for="uitem in item.wuduList" :key="uitem.id" :name="uitem.id" style="padding:10px">
                                            {{ uitem.name ? uitem.name : '' }}
                                        </van-radio>
                                    </van-radio-group>
                                    <!-- 多选 -->
                                    <van-checkbox-group v-model="item.radioPickDegree" v-if="user.timeType.customDegreeMultiple" @change="$forceUpdate()">
                                        <van-checkbox v-for="uitem in item.wuduList" :key="uitem.id" :name="uitem.id" style="padding:10px">
                                            {{ uitem.name ? uitem.name : '' }}
                                        </van-checkbox>
                                    </van-checkbox-group>
                                </div>
                                <van-button @click="choseProjects(item.radioPickDegree, item.wuduList)">
                                    确定
                                </van-button>
                            </div>
                        </van-popup>

                        <!-- 任务里程碑 -->
                        <van-field :label="'任务/里程碑'" :value="item.taskName" :disabled="item.state<=1" readonly 
                            clickable v-if="user.company.packageProject == 1 && !user.timeType.hideStages && (user.company.nonProjectSimple==0 || (item.projectId && projectss.filter(p => p.id == item.projectId)[0].isPublic!=1))" 
                            @click="clickTakKer(index, item)"
                        />
                        <van-popup v-model="item.showTaksDegree" position="bottom" @click-overlay="overlayPopup(index, 'showTaksDegree')">
                            <div class="chooseSomeone">
                                <div class="chooseSomeoneFlex1">
                                    <div class="chooseSomeoneItem" v-for="(sle,sleIndex) in item.newTaskList" :key="sleIndex" @click="selectTask(sle, index)">
                                        {{ sle.taskName }}
                                    </div>
                                </div>
                            </div>
                        </van-popup>

                        <!-- 自定义数值 -->
                        <van-field v-if="user.timeType.customDataActive==1" type="number" v-model="item.customData" :label="user.timeType.customDataName" 
                            placeholder="请输入数字" ></van-field> 
                        <!-- 自定义文本 -->
                        <van-field v-if="user.timeType.customTextActive==1" v-model="item.customText" :label="user.timeType.customTextName" 
                            placeholder="请输入" maxlength="25"></van-field> 

                        <van-cell title="专业进度" v-if="user.company.packageEngineering == 1">
                        </van-cell>
                        <van-field 
                            type="number"  :name="'progress_'+pItem.professionId" input-align="right" 
                            v-for="pItem in item.professionProgress" :key="pItem.professionId"
                            :label="'--    '+pItem.professionName"  
                        >
                        <template slot="input">
                            <van-stepper v-model="pItem.progress" integer min="0" max="100" />%
                        </template>
                        </van-field>
                        
                        <!-- 常规选择时间的方式 -->
                        <!-- 全天上下午模式 -->
                        <div v-if="reportTimeType.multiWorktime==0">
                        <van-field v-if="reportTimeType.type < 2" readonly clickable  :value="reportTimeType.type==0?item.label:(parseFloat(item.workingTime).toFixed(1)+'h')" label="工作时长" placeholder="请选择工作时长(小时)" 
                        @click="clickTimePicker(index, item)" :disabled="item.state<=1"
                        :rules="[{ required: true, message: '请选择工作时长' }]"/>
                        <van-popup v-model="showPickerTime" position="bottom">
                            <van-picker show-toolbar :columns="timeType"  value-key="label" @confirm="choseTimePick" @cancel="showPickerTime = false" />
                        </van-popup>
                        
                        <!-- 选择数字时间长度模式 -->
                        <van-popup v-model="showPickerHours" position="bottom">
                            <van-picker show-toolbar :columns="timeRange" 
                            :default-index="15"
                            @confirm="choseTimePick" @cancel="showPickerHours = false" />
                        </van-popup>

                        <!-- 时间段选择模式 -->
                        <van-field readonly v-if="reportTimeType.type == 2" clickable name="datetimePicker" :value="item.startTime" label="开始时间" placeholder="点击选择时间" 
                            @click="timePickChange(item, 'showStartTime')" :disabled="item.state<=1"
                            :rules="[{ required: true, message: '请选择开始时间' }]"
                            />
                        <van-popup v-model="showStartTime" position="bottom">
                            <van-datetime-picker
                            v-model="startTime"
                            type="time"
                            @confirm="confirmTime(item,0);"
                            @cancel="showStartTime = false"
                            :min-hour="0"
                            :max-hour="23"
                            />
                            <!-- :filter="filter" 原本这个属性在里面 -->
                        </van-popup>
                        <van-field v-if="reportTimeType.type == 2" readonly clickable name="datetimePicker" :value="item.endTime" label="结束时间" placeholder="点击选择时间" 
                            @click="timePickChange(item, 'showEndTime')" :disabled="item.state<=1"
                            :rules="[{ required: true, message: '请选择结束时间' }]" />
                        <van-popup v-model="showEndTime" position="bottom" >
                            <van-datetime-picker
                            v-model="endTime"
                            type="time"
                            :min-hour="0"
                            :max-hour="23"
                            @confirm="confirmTime(item,1)"
                            @cancel="showEndTime = false" 
                            />
                            <!-- :filter="filter" 原本这个属性在里面 -->
                        </van-popup>
                        <van-cell v-if="reportTimeType.type == 3" >
                            <template >
                                <div>
                            <span>用时占比</span>
                            <van-slider :min="5" :step="5" style="width:120px;display:inline-block;margin-left:50px;" :disabled="item.state<=1" v-model="item.progress" :value="100" @change="item.workingTime = (reportTimeType.allday*item.progress/100).toFixed(1)" >
                            <template #button>
                                <div class="custom-button">{{ item.progress }}%</div>
                            </template>
                            </van-slider>
                            <span style="margin-left:10px;float:right;">{{item.workingTime}}小时</span>
                                </div>
                            </template>
                        </van-cell>
                        <van-field class="form_input"
                        v-model="item.content" name="content" type="textarea" :label="user.companyId==781?'具体内容与结果':'工作事项'" placeholder="请输入" :disabled="item.state<=1"
                        rows="3" autosize :rules="user.timeType.workContentState == 1 ? [{ required: true, message: user.companyId==781?'具体内容与结果':'工作事项' }] : null" />
                        </div>
                        
                        <!-- 多个时间和工作事项的选择方式 -->
                        <div v-if="reportTimeType.multiWorktime==1">
                            <div v-for="(timeItem, tindex) in item.worktimeList" :key="tindex" style="position:relative;border:#ccc 0.5px solid;margin:7px;">
                                <van-tag v-if="tindex>0" style="position:absolute; right:-7px;top:-7px;z-index:10;" 
                                    @click="removeTimeItem(item,tindex)">X</van-tag>
                                
                                <!-- 时间段选择模式 -->
                                <van-field readonly v-if="reportTimeType.type == 2" :clickable="true" name="datetimePicker" 
                                :value="timeItem.startTime" label="开始时间" placeholder="点击选择时间" 
                                :rules="[{ required: true, message: '必填项' }]" :disabled="item.state<=1"
                                    @click="showStartDialog(timeItem)"  />
                                
                                <van-field v-if="reportTimeType.type == 2" readonly :clickable="true" name="datetimePicker" 
                                :value="timeItem.endTime" label="结束时间" placeholder="点击选择时间" 
                                :rules="[{ required: true, message: '必填项' }]" :disabled="item.state<=1"
                                    @click="showEndDialog(timeItem)"  />
                                
                                <van-field class="form_input" style="color:#333;-webkit-text-fill-color:#646566;"
                                    :disabled="item.state<=1"
                                    v-model="timeItem.content" name="content" type="textarea" label="工作事项" placeholder="请输入工作事项" 
                                    :rules="user.timeType.workContentState == 1 ? [{ required: true, message: '请填写工作事项' }] : null"
                                    rows="1" autosize  />
                            </div>
                            <!--时间选择器 , 做统一处理，不能放到循环里，不然会有多个公用showStartTime,最后一个会现在最上层UI,导致BUG -->
                            <van-popup v-model="showWorkStartTime" position="bottom">
                                    <van-datetime-picker
                                    v-model="startTime"
                                    type="time"
                                    @confirm="confirmWorkTime(0);"
                                    @cancel="showWorkStartTime = false"
                                    :min-hour="0"
                                    :max-hour="23"
                                    />
                                    <!-- :filter="filter" 原本这个属性在里面 -->
                                </van-popup>
                            <van-popup v-model="showWorkEndTime" position="bottom" >
                                    <van-datetime-picker
                                    v-model="endTime"
                                    type="time"
                                    :min-hour="0"
                                    :max-hour="23"
                                    @confirm="confirmWorkTime(1)"
                                    @cancel="showWorkEndTime = false" 
                                    />
                                    <!-- :filter="filter" 原本这个属性在里面 -->
                                </van-popup>
                        </div>
                        
                        <div style="width:100%;" v-if="reportTimeType.multiWorktime==1">
                        <van-tag  style="text-align:center;padding:5px;margin-left:15px;border: 1px solid #20a0ff;"
                            @click="addNewWorktime(index, item)" 
                            icon="plus" color="#ffffff" ><span style="color:#999;text-align:center;padding: 0 5px;"> 添加工时  </span></van-tag>
                        </div>
                        
                        <div class="overtime" v-if="((user.timeType.fillOvertime || (isWeekend && user.timeType.lockWorktime != 1)) || (isCorpWX&&canEdit)) && user.timeType.enableNewWeeklyfill != 1">
                            <div class="overTimeClas">
                                <van-checkbox  v-model="item.isOvertime" :disabled="item.state<=1" style="width: 4.3rem;">加班</van-checkbox>
                                <van-field v-model="item.overtimeHours" type="number" :disabled="item.isOvertime==null || item.isOvertime==0 || item.state<=1"
                                placeholder="请输入加班时长" style="width: 5rem"></van-field>
                                <span :class="'overListTime'">小时</span>    
                            </div>
                            <van-tag v-if="user.companyId != 4281&& isCorpWX&&canEdit"  style="position:absolute;right:10px;" type="primary" size="large" @click="takePhoto(index)">拍照上传</van-tag>
                        </div>
                        <div style="padding:5px;text-align:center;" v-if="!isIOSystem">
                            <span v-for="(p, index) in item.pics"  :key="p" style="margin-right:15px;">
                            <div class="imgList">    
                                <van-icon v-if=" user.companyId==7" size="20" name="clear"  @click="deleteImg(item, index)" class="imgList_i"/>
                                <img  :src="p" style="width:100px; height:100px;" @click="showLargeImg(item.pics, index)"/>
                            </div>
                            </span>
                        </div>
                        <div style="padding:5px;text-align:center;" v-if="isIOSystem">
                            <span v-for="(p, index) in item.iospics"  :key="p" style="margin-right:15px;">
                            <div class="imgList">
                                <van-icon  v-if=" user.companyId==7" size="20" name="clear"  @click="deleteImg(item, index)" class="imgList_i"/>
                                <img :src="p" style="width:100px; height:100px;" @click="showLargeImg(item.iospics, index)"/>
                            </div>
                            </span>
                        </div>
                        <van-popup v-model="imgShow" position="bottom" closeable >
                            <van-swipe class="my-swipe"  indicator-color="white">
                            <van-swipe-item v-for="(picItem, index) in tmpPics" :key="index">
                                <img :src="picItem" style="width:100%;" />
                            </van-swipe-item>
                            </van-swipe>
                        </van-popup>
                    </van-cell-group>
                    
                </div>

                <!-- 新增项目或者任务 -->
                <div style="text-align:center;" >
                <van-tag size="large" style="text-align:center;margin:10px;padding:12px;margin-bottom:120px;border: 1px solid #20a0ff;"
                    @click="addNewPro" 
                    icon="plus" color="#ffffff" ><span style="color:#999;text-align:center;padding: 0 50px;"> + 新增{{user.companyId==781?'任务':'项目'}}  </span></van-tag>
                </div> 
            </div>
            

            <!-- 周总结 -->
            <div v-if="user.timeType.enableNewWeeklyfill == 1 && inbtn == 999">
                <div class="van-cell-group__title">周总结</div>
                <van-field
                    v-model.trim="summary"
                    rows="9"
                    autosize
                    type="textarea"
                    maxlength="1000"
                    show-word-limit
                    placeholder="请输入周总结"
                />
            </div>
             
            <div class="form_btn" style="position:fixed; bottom:0px;width:100%;">
                <div style="padding-bottom:10px;" v-if="temporaryStorage">
                    <van-button square block type="default" @click="submitReport(1)" native-type="submit" style="width:50%;float:left;">
                        <div>暂存</div>
                    </van-button>
                    <van-button square block type="info" @click="submitReport(0)" native-type="submit" style="width:50%;float:right;">
                        <div>提交</div>
                    </van-button>
                    
                </div>
                <div style="padding-bottom:10px;" v-if="!temporaryStorage">
                    <van-button v-if="this.user.companyId != 5978" square block type="info" @click="submitReport(0)" native-type="submit" style="width:100%;float:right;">
                        <div>提交</div>
                    </van-button>
                    <!--景昱没有暂存功能，直接加在这里-->
                    <van-button v-if="this.user.companyId == 5978" square block type="info" @click="checkDateWorkTime()" native-type="submit" style="width:100%;float:right;">
                        <div>提交</div>
                    </van-button>
                </div>
            </div>
        </van-form>
        <div style="position:fixed; bottom:0px;width:100%;">
            <van-button  v-if="canCancel" block type="default" @click="cancel"> 撤销 </van-button>
        </div>

        <!-- 选择项目弹窗 -->
        <van-popup v-model="showPickerUserddp" position="bottom" style="height: 80%">
            <!-- <van-search v-model="userName" placeholder="请输入项目名称/编号" @clear="sea()" @blur="sea()" @search="sea()" @input="sea()"/>
            <div style="minHeight:300px;">

                <div class="ryuan" style="color: rgb(185 185 185);">近期选择项目</div>
                <div v-for="(item, index) in integrationProjectList" :key="index" class="ryuan" @click="fZr(item, index)">
                    <p>{{item.projectName}}</p>
                    <p style="margin-top: 5px;color: #9697B2;">{{item.projectCode}}</p> 
                </div>

                <div class="ryuan" style="color: rgb(185 185 185);">全部项目</div>
                <div v-for="(item, index) in projectss" :key="item.id" class="ryuan" @click="fZr(item, index)">
                    <p>{{item.projectName}}</p>
                    <p style="margin-top: 5px;color: #9697B2;">{{item.projectCode}}</p> 
                </div>
            </div> -->
            <div class="popupDiv">
                <div class="popupSearch">
                    <van-search v-model="userName" shape="round" background="#F4F4F4" placeholder="请输入项目名称/编号" @clear="sea()" @blur="sea()" @search="sea()" @input="sea()"/>
                </div>

                <div class="popupCon">
                    <div class="popupTitle">近期选择项目</div>

                    <div v-for="(item, index) in integrationProjectList" :key="index" class="popupItem paddingDiv" @click="fZr(item, index)">
                        <div class="popupItemOne" v-if="item.projectName">{{item.projectName}}</div>
                        <p class="popupItemTwo" v-if="item.projectCode">{{item.projectCode}}</p> 
                    </div>

                    <div class="popupTitle marginTop">全部项目</div>

                    <div v-for="(item, index) in projectss" :key="item.id" class="popupItem paddingDiv" @click="fZr(item, index)">
                        <div class="popupItemOne" v-if="item.projectName">{{item.projectName}}</div>
                        <p class="popupItemTwo" v-if="item.projectCode">{{item.projectCode}}</p> 
                    </div>
                </div>
            </div>
        </van-popup> 
    </div>
</template>
<script>
    // Vue.prototype.$wx = wx
    export default {
        data() {
            return {

                showPickerStage: false,
                showPickerTaskGroup: false,
                showPickerSubProject: false,
                isDraft:0,
                showWorkStartTime:false,
                showWorkEndTime:false,
                curWorktime:null,
                isIOSystem:false,
                imgShow: false,
                isCorpWX:false,
                isWX: false,
                showPickerTask:false,
                canCancel:false,
                // canEdit:true,
                showEndTime: false,
                showStartTime: false,
                startTime:'09:00',
                endTime: '18:00',
                nowTime:new Date(),
                showPickerHours: false,
                timeRange:[],
                selectTime:null,
                reportTimeType:{},
                user: JSON.parse(localStorage.userInfo),
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(),
                currentDate: new Date(),
                showPickerTime: false,
                showPicker: false,
                showPickerProject: false,
                showPickDegree: false,
                clickIndex: 0,
                clickTimeIndex: 0,
                showNumberKey: false,
                canClick: 2,
                timeType:[],
                // 判断中航工业 工作日期为周一到周六 companyId = 817
                form: [],
                currentForm: {
                            domains: [{
                                id: null,
                                projectId: "",
                                projectName: "",
                                workingTime: "",
                                content: "",
                                state: 2,
                                multiWorktime:0,
                                worktimeList:[{}],
                                degreeId: ''
                            }],
                        },
                rules: {
                    createDate: [{ required: true, message: '请选择填报日期' }],
                },
                project: [],
                report: "",

                loading: false,
                finished: false,
                // isOvertime: false,
                tmpPics:[],
                dateAr: [],
                showPickerUserddp: false, // 选择项目弹窗
                projectss: [],
                proads: [],
                userName: '',
                proIdx: '',


                dateRange: [],  //周时间数组
                dateText: [],   //时间段字符串
                weekArr: ['周一','周二','周三','周四','周五','周六','周日'],
                inbtn: 0,
                weekIndex: 5,
                weekSwitchIndex: 1,
                kaoqinText: false,
                workTimeText: false,

                reportBasecostList: [],
                isWeekend: false,

                auditorShow: false,
                auditor: {
                    index: 0,
                    auditorIndex: 'auditorFirst',
                    searchText: '',
                    item: {},
                    list: [],
                    searchList: [],
                },
                integrationProjectList: [],
                summary: '', // 周总结

                temporaryStorage: false, // 是否开启暂存
            };
        },
        methods: {
            // 时间选择
            timePickChange(item, filed) {
                if(item.state <= 1) {
                    return
                }
                this[filed] = true
            },
            // 获取地址上的参数
            getUrlKey(name) {
                return (
                    decodeURIComponent(
                        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                            location.href
                        ) || [, ""])[1].replace(/\+/g, "%20")
                    ) || null
                );
            },
            getRecentlyProject() {
                this.$axios.post('/project/nearProject',{})
                .then(res => {
                    if(res.code == 'ok'){
                        this.integrationProjectList = res.data
                    }
                }).catch(err => {this.$toast.clear();this.cardRefLoading = false;})
            },
            auditorClick(domainIndex,auditorIndex){
                this.auditor.index = domainIndex
                this.auditor.auditorIndex = auditorIndex
                this.auditor.searchList.forEach(u=>{
                    if(u.id == this.currentForm.domains[domainIndex][auditorIndex].id){
                        this.auditor.item = u
                    }
                })
                this.auditorShow = true
                
            },
            onSearch(val){
                this.auditor.searchList = [];
                this.auditor.list.forEach(u=>{if (u.name.startsWith(val)) {
                    this.auditor.searchList.push(u);
                }})
            },
            searchAuditor(){
                this.currentForm.domains[this.auditor.index][this.auditor.auditorIndex] = this.auditor.item
                this.auditorShow = false
                console.log('searchAuditor');
            },
            getAllUsersList(){
                this.$axios.post('/user/getSimpleActiveUserList',{})
                .then(res => {
                    if(res.code == 'ok'){
                        this.auditor.list = res.data
                        this.auditor.searchList = res.data
                    }else{
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err => {this.$toast.clear();})
            },

            // 预算来源
            clickPickCostId(i, item) {
                // if (!item.canEdit) return;
                this.clickIndex = i;
                item.showPickerCostId = true;
                this.$forceUpdate();
            },
            choseCostId(value,index){
                if(value){
                    this.currentForm.domains[this.clickIndex].basecostId = value.id;
                    this.currentForm.domains[this.clickIndex].basecostName = value.name;
                }
                this.currentForm.domains[this.clickIndex].showPickerCostId = false;
                this.$forceUpdate();
            },
            getReportBasecostList(){
                this.$axios.post('/project-basecost-setting/getReportBasecostList',{
                    companyId: this.user.companyId
                }).then(res => {
                    if(res.code == 'ok'){
                        this.reportBasecostList = res.data
                        if(this.reportBasecostList.length != 0 && this.user.company.packageProject == 1){
                            this.currentForm.domains[0].basecostId = this.reportBasecostList[0].id;
                            this.currentForm.domains[0].basecostName = this.reportBasecostList[0].name;
                        }
                    }else{
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err => {this.$toast.clear();})
            },



            // 上周下周按钮切换
            SwitchLastorNext(e){
                this.weekSwitchIndex += e
                this.inbtn = 0
                this.getDateRange()
            },
            // 切换星期
            switchWeek(item,index){
                console.log(this.inbtn, this.form)
                if(this.inbtn == 999) {
                    this.inbtn = index
                    return
                }
                this.form[this.inbtn] = this.currentForm
                this.inbtn = index
                this.currentForm = this.form[this.inbtn]
                if(this.reportBasecostList.length != 0 && this.user.company.packageProject == 1){
                    this.currentForm.domains[0].basecostId = this.reportBasecostList[0].id;
                    this.currentForm.domains[0].basecostName = this.reportBasecostList[0].name;
                }
                this.getKaoqin()
                this.getWorkTime()

                this.getEachArray(this.currentForm)
            },
            // 切换按周
            switchWeekly(index) {
                this.inbtn = index
                console.log(this.form)
            },
            addWeekIndex(){
                console.log(this.weekIndex, this.dateRange);
                if(this.dateRange.length < 7){
                    this.weekIndex += 1
                    let time = this.dateRange[this.dateRange.length - 1]
                    this.dateRange.push(new Date(time.getFullYear(),time.getMonth(),time.getDate()+1))
                    let time2 = new Date(this.dateText[1])
                    let time3 = new Date(time2.getFullYear(),time2.getMonth(),time2.getDate()+1)
                    this.dateText[1] = this.format(time3,'yyyy-MM-dd')
                    this.form.push({
                            createDate: this.format(this.dateRange[this.dateRange.length - 1],"yyyy-MM-dd"),
                            domains: [{
                                id: null,
                                projectId: "",
                                projectName: "",
                                workingTime: this.user.timeType.allday,
                                content: "",
                                progress: 100,
                                state: 2,
                                multiWorktime:0,
                                worktimeList:[{}],
                                degreeId: '',

                                auditorFirst: {name:'',id:''},
                                auditorSec: {name:'',id:''},
                                auditorThird: {name:'',id:''},
                                ccUserid: {name:'',id:''}
                            }],
                        })
                }
            },
            
            // 获取日考勤记录
            getKaoqin(){
                this.$axios.post('/report/getCardTime',{
                    date: this.currentForm.createDate
                }).then(res => {
                    if(res.code == 'ok'){
                        if(res.data){
                            this.kaoqinText = res.data.startTime + '-' + res.data.endTime + '，工作' + res.data.workHours + 'h';
                            if (res.data.askLeaveTime) {
                                this.kaoqinText += ' | 请假'+res.data.askLeaveTime+'h';
                            }
                            if (res.data.otTime) {
                                this.kaoqinText += ' | 加班'+res.data.otTime+'h';
                            }
                            if((this.user.timeType.showDdCardtime == 1 || this.user.timeType.showCorpwxCardtime == 1)){
                                this.$set(this.currentForm,'cardtime',res.data.workHours)
                            }
                        }else {
                            this.kaoqinText = false
                        }
                    }else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err => {
                    this.$toast.clear();
                    this.$toast.fail(err);
                })
            },
            getWorkTime(){
                let dateStr = [this.currentForm.createDate]
                this.$axios.post('/report/getWeeklyWorkTime',{
                    dateStr: JSON.stringify(dateStr)
                }).then(res => {
                    if(res.code == 'ok'){
                        var sumTimeList = res.data;
                        if(sumTimeList.length != 0){
                            this.workTimeText = sumTimeList[0].workingTime

                            this.currentForm.reportTimes = sumTimeList[0].startTime
                        }else{
                            this.workTimeText = false
                        }
                    }else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err => {
                    this.$toast.clear();
                    this.$toast.fail(err);
                })
            },
            // 获取周填报时间数据
            async getDateRange(){
                // if(this.user.companyId == 817){
                //     this.weekIndex = 6
                // }else{
                //     this.weekIndex = 5
                // }
                const { enableNewWeeklyfill } = this.user.timeType // 针对物奇
                if(this.user.timeType.alertNonWorkday) {
                    if(this.user.timeType.alertNonWorkday == '110' || this.user.timeType.alertNonWorkday == '10') {
                        this.weekIndex = 7
                    }
                    if(this.user.timeType.alertNonWorkday == '100') {
                        this.weekIndex = 6
                    }
                } else {
                    this.weekIndex = 5
                }

                let nowDate = new Date()

                console.log(nowDate, '<=== nowDate')
                // 针对物奇
                if(enableNewWeeklyfill == 1) {
                    const urldate = this.getUrlKey('date')
                    if(urldate) {
                        nowDate = new Date(urldate)
                    }
                }
                

                let nowWeekday = nowDate.getDay()

                let shuldDate
                if(nowWeekday == 0){
                    // 取上周日期
                    shuldDate = new Date(nowDate.getFullYear(),nowDate.getMonth(),nowDate.getDate() - 7*this.weekSwitchIndex)
                }else{
                    // 取本周日期
                    shuldDate = new Date(nowDate.getFullYear(),nowDate.getMonth(),nowDate.getDate() - 7*(this.weekSwitchIndex - 1))
                }

                // 时间段数组
                let date = new Date(shuldDate.getFullYear(),shuldDate.getMonth(),shuldDate.getDate()-shuldDate.getDay()+1)
                console.log(this.formatDate(date), '<=== date', this.formatDate(shuldDate), this.formatDate(nowWeekday))
                this.dateRange = []
                let newWeeklyDataList = [];
                let serverPList = [];
                if(enableNewWeeklyfill) { // 针对物奇
                    let { data } = await this.$axios.post('/report/getWeeklyFillReportData', {
                        targetDate: this.formatDate(date)
                    })
                    serverPList = data.projectList;
                    let { dateList } = data
                    this.dateRange = dateList.map(item => new Date(item.date))
                    this.dateText = [dateList[0].date, dateList[dateList.length - 1].date]
                    newWeeklyDataList = dateList;
                } else {
                    for(let i=0;i<this.weekIndex;i++){
                        this.dateRange.push(new Date(date.getFullYear(),date.getMonth(),date.getDate()+i))
                    }
                }
                
                // 时间段字符串
                let startDate = this.dateRange[0]
                let startDateM = startDate.getMonth() + 1
                let startDateD = startDate.getDate()

                // let endDate = this.dateRange[this.weekIndex - 1]
                let endDate = this.dateRange[this.dateRange.length-1];
                let endDateM = endDate.getMonth() + 1
                let endDateD = endDate.getDate()

                let startDateStr = startDate.getFullYear() + '-' + (startDateM < 10 ? '0' + startDateM : startDateM) + '-' + (startDateD < 10 ? '0' + startDateD : startDateD)
                let endDateStr = endDate.getFullYear() + '-' + (endDateM < 10 ? '0' + endDateM : endDateM) + '-' + (endDateD < 10 ? '0' + endDateD : endDateD)

                this.form = []
                if(enableNewWeeklyfill != 1) {
                    this.dateText = [startDateStr,endDateStr]
                    for(let i=0;i<this.dateRange.length;i++){
                        let formItem = {
                                createDate: this.format(this.dateRange[i],"yyyy-MM-dd"),
                                domains: [{
                                    id: null,
                                    projectId: "",
                                    projectName: "",
                                    workingTime: this.user.timeType.allday,
                                    content: "",
                                    progress: 100,
                                    state: 2,
                                    multiWorktime:0,
                                    worktimeList:[{}],
                                    degreeId: '',

                                    auditorFirst: {name:'',id:''},
                                    auditorSec: {name:'',id:''},
                                    auditorThird: {name:'',id:''},
                                    ccUserid: {name:'',id:''}
                                }],
                            }
                        this.form.push(formItem)
                    }
                } else {
                    //启用了新版按周填报，加载接口返回的日报数据
                    for(let i=0;i<newWeeklyDataList.length;i++){
                        let oneDayItem = newWeeklyDataList[i];
                        console.log('111',oneDayItem.date);
                        let formItem = {
                            createDate: oneDayItem.date,
                            // domains: [{
                            //     id: null,
                            //     projectId: "",
                            //     projectName: "",
                            //     workingTime: this.user.timeType.allday,
                            //     content: "",
                            //     progress: 100,
                            //     state: 2,
                            //     multiWorktime:0,
                            //     worktimeList:[{}],
                            //     degreeId: '',

                            //     auditorFirst: {name:'',id:''},
                            //     auditorSec: {name:'',id:''},
                            //     auditorThird: {name:'',id:''},
                            //     ccUserid: {name:'',id:''}
                            // }],
                        }
                        //日报数据
                        let reportList = oneDayItem.reportList;
                        reportList.forEach(r=>r.projectName = serverPList.filter(s=>s.id==r.projectId)[0].projectName);
                        if (reportList.length > 0) {
                            formItem.domains = reportList;
                        } else {
                            formItem.domains = [{
                                id: null,
                                projectId: "",
                                projectName: "",
                                workingTime: this.user.timeType.allday,
                                content: "",
                                progress: 100,
                                state: 2,
                                multiWorktime:0,
                                worktimeList:[{}],
                                degreeId: '',
                                auditorFirst: {name:'',id:''},
                                auditorSec: {name:'',id:''},
                                auditorThird: {name:'',id:''},
                                ccUserid: {name:'',id:''}
                            }]
                        }
                        this.form.push(formItem)
                    }
                }

                

                
                this.currentForm = this.form[0]
                if(this.reportBasecostList.length != 0 && this.user.company.packageProject == 1){
                    this.currentForm.domains[0].basecostId = this.reportBasecostList[0].id;
                    this.currentForm.domains[0].basecostName = this.reportBasecostList[0].name;
                }
                this.getKaoqin()
                this.getWorkTime()

                this.getWeeklyReportData()
            },
            // 周日期按钮状态
            canSelect(item){
                if(this.weekSwitchIndex < 2 && this.user.timeType.fillAhead == 0){
                    let date = new Date()
                    if(date.getTime() > item.getTime()){
                        return false
                    }else{
                        return true
                    }
                }else {
                    return false
                }
            },



            //获取项目审核人
            getProjectAuditorList(domainItem) {
                this.$axios.post("/project-auditor/getList", {projectId: domainItem.projectId})
                .then(res => {
                    if(res.code == "ok") {
                        domainItem.auditUserList = res.data;
                        if (res.data.length==1) {
                            domainItem.projectAuditorId = domainItem.auditUserList[0].auditorId;
                            domainItem.projectAuditorName = domainItem.auditUserList[0].auditorName;
                        }
                        this.$forceUpdate();
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();this.$toast.fail(err);});
            },

            updateTxt() {
                this.$forceUpdate();
            },
            // 获取项目
            getPeoject() {
                this.$axios.post("/project/getProjectList", {forReport:1})
                .then(res => {
                    if(res.code == "ok") {
                        for(var i in res.data) {
                            if(res.data[i].projectCode == 'null' || res.data[i].projectCode == null) {
                                res.data[i].projectCode = ' '
                            }
                        }
                        this.projectss = res.data;
                        this.projectss = this.projectss.filter(p=>p.status == 1);
                        this.proads = res.data

                        this.getRecentlyProject()
                    } else {
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },
            sea() {
                // console.log(this.userName.length)
                if(this.userName.length > 0) {
                    let data = this.proads.filter(item => {return  item.projectName.indexOf(this.userName.toUpperCase()) != '-1'});
                    let dataList = this.proads.filter(item => {return  item.projectCode.indexOf(this.userName.toUpperCase()) != '-1'});
                    let dataTree = data.concat(dataList)
                    let arrList = Array.from(new Set(dataTree))
                    this.projectss = arrList
                } else {
                    // console.log(456)
                    this.projectss = this.proads
                }
            },
            fZr(item, index, flag = false, newProIdx) {
                console.log(flag, '《=== 回显')
                if(flag) { // flag 为 true 时，表示回显数据
                    this.proIdx = newProIdx
                }

                var domainItem = this.currentForm.domains[this.proIdx];

                if(!flag) { // 选择项目时清空，回显数据不做操作
                    domainItem.projectId = item.id;
                    domainItem.projectName = item.projectName;
                    //清空子项目
                    domainItem.subProjectId = null;
                    domainItem.subProjectName = null;
                    //清空任务
                    domainItem.taskId = null;
                    domainItem.taskName = null;
                    //清空分组和阶段
                    domainItem.stage = null;
                    domainItem.groupId = null;
                    // 清空自定义维度
                    domainItem.weiduName = '';
                    domainItem.wuduList = []
                    domainItem.degreeId = ''
                    domainItem.radioPickDegree = ''
                }

                this.showPickerUserddp = false;
                //获取子项目
                if(this.user.timeType.mainProjectState != 1 && this.user.timeType.enableNewWeeklyfill != 1){
                    this.getSubprojectList(this.currentForm.domains[this.proIdx]);
                }
                //加载项目相关的工程进度
                if (this.user.company.packageEngineering == 1){
                    this.getProjectProfessions(this.currentForm.domains[this.proIdx],index);
                }
                //获取任务分组
                if(this.user.timeType.enableNewWeeklyfill == 1) { // 针对物奇获取项目分组
                    this.getProjectGroup(this.currentForm.domains[this.proIdx], this.proIdx)
                } else {
                    this.getTaskGroups(this.currentForm.domains[this.proIdx], this.proIdx);
                    // 获取1相关维度
                    this.getTaskList(domainItem.projectId)
                    //获取相关日报提交选择人
                    domainItem.projectAuditorId = null;
                    domainItem.projectAuditorName = null;
                    domainItem.auditUserList = null;
                    //获取项目审核人
                    var curProject = this.project.filter(p=>p.id == domainItem.projectId)[0];
                    if (this.user.timeType.reportAuditType == 0) {
                        this.getProjectAuditorList(domainItem, index);
                    }
                }
            },
            //获取项目下的任务分组
            getTaskGroups(domainItem, index) {
                domainItem.groupId=null;
                this.$axios.post("/task-group/listMyJoinGroup", {projectId: domainItem.projectId})
                    .then(res => {
                        if(res.code == "ok") {
                            domainItem.taskGroups = res.data;
                        } 
                        this.$forceUpdate();
                    }).catch(err=> {this.$toast.clear();});
            },
            getSubprojectList(domainItem) {
                domainItem.subProjectList = [];
                this.$axios.post("/sub-project/list", {projectId: domainItem.projectId,isReport:true})
                    .then(res => {
                        if(res.code == "ok") {
                            domainItem.subProjectList = res.data;
                            const { subProjectId, subProjectName } = domainItem
                            if(subProjectId && !subProjectName) {
                                domainItem.subProjectName = res.data.filter(item => item.id == subProjectId)[0].name
                            }
                        } 
                        this.$forceUpdate();
                    }).catch(err=> {this.$toast.clear();});
            },
            showEndDialog(timeItem) {
                this.curWorktime = timeItem;
                this.showWorkEndTime = true;
            },
            showStartDialog(timeItem) {
                this.curWorktime = timeItem;
                this.showWorkStartTime = true;
            },
            removeTimeItem(item, index) {
                item.worktimeList.splice(index, 1);
            },
            isIOS(){
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                return isiOS;
            },
            
            showLargeImg(item, index) {
                this.imgShow = true;
                this.tmpPics = item;
            },
            //拍照上传
            takePhoto(index) {
                var that = this;
                wx.chooseImage({
                    count: 9, // 默认9
                    sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
                    defaultCameraMode: "batch", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
                    isSaveToAlbum: 0, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
                    success: function (res) {
                        var localIds = res.localIds; // 返回选定照片的本地ID列表，
                                // andriod中localId可以作为img标签的src属性显示图片；
                                // iOS应当使用 getLocalImgData 获取图片base64数据，从而用于img标签的显示（在img标签内使用 wx.chooseImage 的 localid 显示可能会不成功）
                        if (that.currentForm.domains[index].pics == null) {
                            that.currentForm.domains[index].pics = [];
                            that.currentForm.domains[index].iospics = [];
                        }
                        if (that.isIOSystem) {
                            for (var i=0;i<localIds.length; i++) {
                                wx.getLocalImgData({
                                    localId: localIds[i], // 图片的localID
                                    success: function (res) {
                                        var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                                        that.currentForm.domains[index].iospics.push(localData);
                                        that.$forceUpdate();
                                    }
                                });
                            }
                            
                            if (that.user.companyId == 7) {
                                that.currentForm.domains[index].pics = that.currentForm.domains[index].pics.concat(localIds); 
                            } else {
                                that.currentForm.domains[index].pics = localIds;
                            }
                        } else {
                            if (that.user.companyId == 7) {
                                that.currentForm.domains[index].pics = that.currentForm.domains[index].pics.concat(localIds); 
                            } else {
                                that.currentForm.domains[index].pics = localIds;
                            }
                            that.$forceUpdate();                            
                        }
                        // var serverIdList = [];
                        if (that.currentForm.domains[index].serverPics == null) {
                            that.currentForm.domains[index].serverPics = [];
                        }
                        //立即就上传到企业微信服务器
                        for (var i=0;i<localIds.length; i++) {
                            wx.uploadImage({
                                localId: localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
                                isShowProgressTips: 1, // 默认为1，显示进度提示
                                success: function (res) {
                                    var serverId = res.serverId; // 返回图片的服务器端ID
                                    // serverIdList.push(serverId);
                                    that.currentForm.domains[index].serverPics.push(serverId);
                                }
                            });
                        }
                    }
                });
            },
            copyProject(index) {
                var leftProgress = 10;
                if (this.reportTimeType.type == 3) {
                    //计算已经待分配工时比例
                    let array = this.currentForm.domains;
                    let totalProgress = 0;
                    for (var i=0;i<array.length; i++) {
                        totalProgress += array[i].progress;
                    }
                    if (totalProgress < 100) {
                        leftProgress = 100 - totalProgress;
                    }
                }
                var newIndex = index+1;
                var pName = "";
                if (this.currentForm.domains[index].projectId != '') {
                    pName = this.project.filter(p=>p.id == this.currentForm.domains[index].projectId)[0].projectName;
                }
                var itemDomain = {
                    id: null,
                    projectId: this.currentForm.domains[index].projectId,
                    projectName: pName,
                    workingTime: this.reportTimeType.type==3?(leftProgress*this.reportTimeType.allday/100).toFixed(1):"",
                    progress:leftProgress,
                    content: "",
                    state: 2,
                    isOvertime:false,
                };
                this.currentForm.domains.splice(newIndex, 0,itemDomain);
            },
            //删除日报
            deleteReport() {
                this.$dialog.confirm({
                    title: '删除日报',
                    message: '确定要删除当天日报吗?'
                }).then(() => {
                    const toast = this.$toast.loading({
                        forbidClick: true,
                        duration: 0
                    });
                    this.$axios.post("/report/delete", {userId: this.user.id, date:this.currentForm.createDate})
                    .then(res => {
                        if(res.code == "ok") {
                            this.$toast.clear();
                            this.$toast.success('删除成功');
                            window.location.reload();
                        } else {
                            this.$toast.clear();
                            this.$toast.fail('删除失败');
                        }
                    }).catch(err=> {this.$toast.clear();});
                }).catch(() => {});
            },
            changeAllTime() {
                //总时长发生改变，自动按比例计算
                this.currentForm.domains.forEach(d=>{
                    d.workingTime = (d.progress*this.reportTimeType.allday/100).toFixed(1);
                });
            },
            cancel() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                var ids = '';
                var data = this.currentForm.domains;
                data.forEach(element => {
                    if (element.id != null && element.id != '') {
                        ids +=(element.id+',');
                    }
                });
                this.$axios.post("/report/cancel", {userId: this.user.id, reportIds:ids})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success('撤销成功');
                        // this.getReport();
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            confirmWorkTime(field) {
                if (field == 0) {
                    this.curWorktime.startTime = this.startTime;
                    this.showWorkStartTime = false;
                } else {
                    this.curWorktime.endTime = this.endTime;
                    this.showWorkEndTime = false;
                }
            },

            confirmTime(item, field) {
                if (field == 0) {
                    item.startTime = this.startTime;
                    this.showStartTime = false;
                } else {
                    item.endTime = this.endTime;
                    this.showEndTime = false;
                }
            },
            filter(type, options) {
                if (type === 'minute') {
                    return options.filter(option => option % 30 === 0);
                }

                return options;
            },
            choseTimePick(value, index) {
                if(!value){
                    return
                }
                //选中时间
                if (this.reportTimeType.type == 0) {
                    this.currentForm.domains[this.clickTimeIndex].timeType = value.value;
                    this.currentForm.domains[this.clickTimeIndex].workingTime = value.hours;
                    this.currentForm.domains[this.clickTimeIndex].label = value.label;
                    this.showPickerTime = false;
                } else if (this.reportTimeType.type == 1) {
                    // console.log('this.reportTimeType.type=='+value);
                    this.currentForm.domains[this.clickTimeIndex].workingTime = value;
                    this.currentForm.domains[this.clickTimeIndex].label = value.toFixed(1)+'小时';
                    this.showPickerHours = false;

                    let allhour = 0
                    for(let i in this.currentForm.domains){
                        this.currentForm.domains[i].isOvertime = false
                        delete this.currentForm.domains[i].overtimeHours
                        allhour += this.currentForm.domains[i].workingTime
                    }
                    if(allhour > this.user.timeType.allday){
                        this.currentForm.domains[this.clickTimeIndex].isOvertime = true
                        this.currentForm.domains[this.clickTimeIndex].overtimeHours = allhour - this.user.timeType.allday
                    }
                }
      
            },
            clickTimePicker(i, item) {
                if (item.state <=1) return
                this.clickTimeIndex = i;
                
                if (this.reportTimeType.type == 0) {
                    this.showPickerTime = true;
                } else if (this.reportTimeType.type == 1) {
                    this.showPickerHours = true;
                }
            },
            getTimeType() {
                this.$axios.post('/time-type/getCompanyTimeSetting', { companyId: this.user.companyId})
                .then(res => {
                    if(res.code == "ok") {
                        var t = res.data;
                        this.reportTimeType = t;
                        //转化时间格式
                        if (t.allday != null) {
                            this.timeType.push({value:0, label:'全天 - '+t.allday+'小时', hours:t.allday});
                        }
                        if (t.am != null) {
                            this.timeType.push({value:1, label:'上午 - '+t.am+'小时', hours: t.am});
                        }
                        if (t.pm != null) {
                            this.timeType.push({value:2, label:'下午 - '+t.pm+'小时', hours: t.pm});
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            // 返回
            back() {
                history.back();
            },

            // 时间转换
            format(date, pattern) {
                pattern = pattern || "yyyy-MM-dd";
                var _this = this;
                return pattern.replace(/([yMdhsm])(\1*)/g, function ($0) {
                    switch ($0.charAt(0)) {
                        case 'y': return _this.padding(date.getFullYear(), $0.length);
                        case 'M': return _this.padding(date.getMonth() + 1, $0.length);
                        case 'd': return _this.padding(date.getDate(), $0.length);
                        case 'w': return date.getDay() + 1;
                        case 'h': return _this.padding(date.getHours(), $0.length);
                        case 'm': return _this.padding(date.getMinutes(), $0.length);
                        case 's': return _this.padding(date.getSeconds(), $0.length);
                    }getReport
                });
            },

            padding(s, len) {
                var len = len - (s + '').length;
                for (var i = 0; i < len; i++) { s = '0' + s; }
                return s;
            },

            // 获取项目
            getProject() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/project/getProjectList", {forReport:1})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.project = res.data;
                        
                        // if (this.project.length > 0) {
                        //     console.log('this.project[0].id=='+this.project[0].id);
                        //     this.getTaskList(this.project[0].id);
                        // }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            // 获取日报
            getReport() {
                this.isWeekend = false;
                let weekday = new Date(this.form.createDate).getDay()
                if(this.user.companyId == 817){
                    if(weekday == 0){
                        this.isWeekend = true
                    }
                }else{
                    if(weekday == 0 || weekday == 6){
                        this.isWeekend = true
                    }
                }
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/report/getReport", {date: this.currentForm.createDate})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.report = res.data;
                        var t = res.data.timeType;
                        var timeType=[];
                        //转化时间格式
                        if (t.allday != null) {
                            timeType.push({value:0, label:'全天 - '+t.allday+'小时', hours:t.allday});
                        }
                        if (t.am != null) {
                            timeType.push({value:1, label:'上午 - '+t.am+'小时', hours: t.am});
                        }
                        if (t.pm != null) {
                            timeType.push({value:2, label:'下午 - '+t.pm+'小时', hours: t.pm});
                        }
                        var list = res.data.report;
                        if(list.length != 0) {
                            // this.canEdit = false;
                            this.canCancel = false;
                            let array = [];
                            for(var i in list) {
                                var projectName = "";
                                var flg = (list[i].isOvertime == 1);
                                for(var j in this.project) {
                                    if(this.project[j].id == list[i].projectId) {
                                        projectName = this.project[j].projectName;
                                    }
                                }
                                let tname = '';
                                if (list[i].taskId != null && list[i].taskList.length > 0) {
                                    let filterList = list[i].taskList.filter(t=>t.taskId == list[i].taskId);
                                    if (filterList.length > 0) {
                                        tname = filterList[0].taskName;
                                    }
                                } 
                                let mmm = list[i].degreeId
                                let sss = list[i].degreeList
                                let wuduName = ''
                                for (var s in sss) {
                                    if(sss[s].id == mmm) {
                                        wuduName = sss[s].name
                                    }
                                }
                                let serverPicArray = [];
                                // console.log('picArray=='+list[i].picStr);
                                if (list[i].picStr != null && list[i].picStr != '@') {
                                    serverPicArray = JSON.parse(list[i].picStr.replace(/@/g,","));
                                }
                                
                                var subProjectName = null;
                                if (list[i].subProjectId) {
                                    subProjectName = list[i].subProjectList.filter(s=>s.id==list[i].subProjectId)[0].name;
                                }
                                array.push({
                                    id: list[i].id,
                                    projectId: list[i].projectId,
                                    projectName: projectName,
                                    workingTime: String(list[i].workingTime),
                                    content: list[i].content,
                                    state: list[i].state,
                                    timeType: list[i].timeType,
                                    label: timeType[list[i].timeType].label,
                                    startTime: list[i].startTime,
                                    endTime: list[i].endTime,
                                    isOvertime: flg,
                                    progress: list[i].progress,
                                    taskList: list[i].taskList,
                                    taskId: list[i].taskId ? list[i].taskId : null,
                                    taskName:tname,
                                    professionProgress:list[i].professionProgressList,
                                    pics: list[i].pics,
                                    iospics:list[i].pics,
                                    serverPics:serverPicArray,
                                    multiWorktime:t.multiWorktime,
                                    worktimeList:list[i].worktimeList,
                                    wuduList: list[i].degreeList,
                                    weiduName: wuduName,
                                    degreeId: list[i].degreeId,
                                    subProjectId: list[i].subProjectId,
                                    subProjectName: subProjectName,
                                    subProjectList: list[i].subProjectList,
                                    groupId: list[i].groupId == 0?null:list[i].groupId,
                                    groupName: list[i].groupName,
                                    taskGroups:list[i].taskGroups,
                                    stages:list[i].stages,
                                    stage:list[i].stage == '-'?null:list[i].stage,
                                    customData: list[i].customData,
                                    projectAuditorId: list[i].projectAuditorId,
                                    projectAuditorName: list[i].projectAuditorName,
                                    auditUserList: list[i].auditUserList,
                                    overtimeHours: list[i].overtimeHours,
                                    customText: list[i].customText
                                })
                                if (list[i].state >= 2) {
                                    // this.canEdit = true;
                                } else if (list[i].state == 0) {
                                    this.canCancel = true;
                                }
                            }
                            this.currentForm.domains = array;
                        } else {
                            this.canCancel = false;
                            // this.canEdit = true;
                            //没有填报的可以点击提交
                            this.currentForm.domains = [{
                                id: null,
                                projectId: "",
                                projectName: "",
                                workingTime: t.type==3?(t.allday).toFixed(1):"8.0",
                                content: "",
                                state: 2,
                                progress:100,
                                isOvertime:false,
                                professionProgress:[],
                                multiWorktime:t.multiWorktime,
                                worktimeList:[{}],  
                            }]
                            // this.canEdit = true;
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                    // if(localStorage.getItem("formVal")) {
                    //     console.log('触发了')
                    //     var obj = localStorage.getItem("formVal")
                    //     this.form = JSON.parse(obj)
                    //     localStorage.removeItem('formVal')
                    // }
                }).catch(err=> {this.$toast.clear();});
            },

            // 改变时间
            changeTime(time) {
                this.currentForm.createDate = this.format(new Date(time),"yyyy-MM-dd");
                this.currentDate = time;
                this.showPicker = false;
                // this.getReport();
            },

            //点击选择审核人
            clickPickAuditor(i, item) {
                //不可待审核和已通过不可编辑
                if (item.state<2) return;
                if (item.auditUserList.length<=1) return;
                this.clickIndex = i;
                item.showPickerAuditor = true;
                this.$forceUpdate();
            },

            choseAuditor(value, index) {
                if(value){
                    this.currentForm.domains[this.clickIndex].projectAuditorId = value.auditorId;
                    this.currentForm.domains[this.clickIndex].projectAuditorName = value.auditorName;
                }
                this.currentForm.domains[this.clickIndex].showPickerAuditor = false;
                this.$forceUpdate();
            },

            //点击选择子项目
            clickPickSubProject(i, item) {
                //不可待审核和已通过不可编辑
                if (item.state<2) return;
                this.clickIndex = i;
                item.showPickerSubProject = true;
                this.$forceUpdate();
            },
            clickPickTaskGroup(i, item) {
                if(item.state <= 1) {
                    return
                }
                this.clickIndex = i;
                item.showPickerTaskGroup = true;
                this.$forceUpdate();
            },
            clickPickStage(i, item) {
                //不可待审核和已通过不可编辑
                if (item.state<2) return;
                this.clickIndex = i;
                item.showPickerStage = true;
                this.$forceUpdate();
            },

            // 选择项目
            clickPicker(i, item) {
                //不可待审核和已通过不可编辑
                if (item.state<2) return;
                // this.clickIndex = i;
                // this.showPickerProject = true;
                // console.log(i, item, this.currentForm)
                this.proIdx = i
                this.showPickerUserddp = true
                // this.$router.push({
                //     path: '/search',
                //     query: {
                //         // from: JSON.stringify(this.form)
                //         from: this.form,
                //         idx: i
                //     }
                // });
            },
            clickPickers(i, item) {
                // if (!this.canEdit) return;
                const { state } = item
                if(state < 1) return
                this.clickIndex = i;
                this.currentForm.domains[this.clickIndex].showPickDegree = true;
                var proId = this.currentForm.domains[this.clickIndex].projectId
                this.dimension(proId)
            },
            clickTakKer(i, item) {
                const { state, taskList, groupId } = item
                if(state < 1) return
                this.clickIndex = i;
                let taskListJson = JSON.parse(JSON.stringify(taskList))
                this.currentForm.domains[this.clickIndex].newTaskList = taskListJson.filter(item => item.groupId == groupId);
                this.currentForm.domains[this.clickIndex].showTaksDegree = true;
                var proId = this.currentForm.domains[this.clickIndex].projectId
                this.$forceUpdate();
            },
            overlayPopup(index, fields) {
                console.log('触发')
                this.currentForm.domains[index][fields] = false
                this.$forceUpdate();
            },
            selectTask(item, i) {
                this.clickIndex = i;
                this.currentForm.domains[this.clickIndex].taskId = item.taskId;
                this.currentForm.domains[this.clickIndex].taskName = item.taskName;
                this.currentForm.domains[this.clickIndex].showTaksDegree = false
                this.$forceUpdate();
            },
            //选择任务
            clickPickerTask(i) {
                // if (!this.canEdit) return;
                // console.log(i);
                this.clickIndex = i;
                // var proId = this.form.domains[this.clickIndex].projectId
                // this.getTaskList(proId)
                this.currentForm.domains[this.clickIndex].showPickerTask = true;
                this.$forceUpdate();
            },
            choseTask(value, index) {
                if(value){
                    this.currentForm.domains[this.clickIndex].taskId = value.taskId;
                    this.currentForm.domains[this.clickIndex].taskName = value.taskName;
                }
                this.currentForm.domains[this.clickIndex].showPickerTask = false;
                this.$forceUpdate();
            },

            choseProjects(value, arrList) {
                console.log(value)
                // return
                const { timeType } = this.user
                if(value){
                    if(!timeType.customDegreeMultiple) { // 单选
                        let list = arrList.filter(item => item.id == value)[0]
                        this.currentForm.domains[this.clickIndex].weiduName = list.name;
                        this.currentForm.domains[this.clickIndex].degreeId = list.id;
                    }   
                    if(timeType.customDegreeMultiple) { // 多选
                        let list = arrList.filter(s => value.includes(+s.id))
                        this.currentForm.domains[this.clickIndex].weiduName = list.map(item => item.name).join(',');
                        this.currentForm.domains[this.clickIndex].degreeId = list.map(item => item.id).join(',');
                    }
                }
                this.currentForm.domains[this.clickIndex].showPickDegree = false;

                console.log(this.currentForm.domains[this.clickIndex], '再次')
                this.$forceUpdate();
            },

            choseSubProject(value, index) {
                if(value){
                    this.currentForm.domains[this.clickIndex].subProjectId = value.id;
                    this.currentForm.domains[this.clickIndex].subProjectName = value.name;
                }
                this.currentForm.domains[this.clickIndex].showPickerSubProject = false;
                this.$forceUpdate();
            },

            choseTaskGroup(value, index, flag = false, newClickIndex) {
                if(!value){
                    return
                }

                if(flag) {
                    this.clickIndex = newClickIndex
                }

                this.currentForm.domains[this.clickIndex].groupId = value.id;
                this.currentForm.domains[this.clickIndex].groupName = value.name;
                this.currentForm.domains[this.clickIndex].showPickerTaskGroup = false;
                
                var domainItem = this.currentForm.domains[this.clickIndex];

                this.getGroupStages(this.currentForm.domains[this.clickIndex], index, flag);
                
                //检查当前的项目是否需要获取分组的负责人
                var curProject = this.project.filter(p=>p.id == this.currentForm.domains[this.clickIndex].projectId)[0];
                if (this.user.timeType.reportAuditType >= 1) {
                    this.$axios.post("/task-group/getGroupIncharger", {groupId: domainItem.groupId})
                    .then(res => {
                        if(res.code == "ok") {
                            domainItem.auditUserList = res.data;
                            if (res.data.length==1) {
                                domainItem.projectAuditorId = domainItem.auditUserList[0].auditorId;
                                domainItem.projectAuditorName = domainItem.auditUserList[0].auditorName;
                            }
                            this.$forceUpdate();
                        } else {
                            this.$toast.fail('获取失败:'+res.msg);
                        }
                    }).catch(err=> {this.$toast.clear();});
                }
            },
            choseStage(value, index) {
                if(value){
                    this.currentForm.domains[this.clickIndex].stage = value.stagesName;
                }
                this.currentForm.domains[this.clickIndex].showPickerStage = false;
                this.$forceUpdate();

            },

            getGroupStages(domain, index, flag = false) {
                this.$axios.post("/stages/getProjectStagesByGroup", {groupId: domain.groupId})
                        .then(res => {
                            if(res.code == "ok") {
                                if(!flag) {
                                    domain.stage = '';
                                }
                                domain.stages = res.data;
                                this.$forceUpdate();
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.clear();});
            },


            getProjectProfessions(domain, index) {
                // console.log(domain, '数据')
                this.$axios.post("/project-profession/getMyProfession", {projectId: domain.projectId})
                        .then(res => {
                            if(res.code == "ok") {
                                this.currentForm.domains[this.clickIndex].professionProgress = res.data;
                                // console.log(this.currentForm.domains)
                                this.$forceUpdate();
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.clear();});
            },
            // 获取维度
            dimension(projectId, index, flag = false, customDegreeMultiple, newClickIndex) {
                this.$axios.post("/project/getDegreeList", {projectId: projectId})
                .then(res => {
                    if(res.code == "ok") {
                        res.data.forEach(item => {
                            item.id = +item.id
                        })
                        // if(flag) {
                        //     this.clickIndex = newClickIndex
                        // }
                        this.currentForm.domains[this.clickIndex].wuduList = res.data;
                        // let degreeId = this.currentForm.domains[this.clickIndex].degreeId
                        // for(let i in degreeId) {
                        //     degreeId[i] = +degreeId[i]
                        // }
                        // if(flag) {
                        //     if(!customDegreeMultiple) { // 单选
                        //         this.currentForm.domains[this.clickIndex].radioPickDegree = res.data.filter(item => item.id == degreeId)[0]
                        //         this.currentForm.domains[this.clickIndex].weiduName = res.data.filter(item => item.id == degreeId)[0].name
                        //     } else {
                        //         let arr = res.data.filter(s => degreeId.includes(+s.id)).map(s => s.name);
                        //         this.currentForm.domains[this.clickIndex].weiduName = arr.join(',')
                        //     }
                        // }

                        // console.log(this.currentForm.domains[this.clickIndex])
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },


            getTaskList(projectId, index, flag = false, newClickIndex) {
                //如果是专业版，需要列出任务列表
                if (this.user.company.packageProject == 1) {
                    this.$axios.post("/task/getRecentTask", {projectId: projectId})
                        .then(res => {
                            if(res.code == "ok") {
                                if(flag) {
                                    this.clickIndex = newClickIndex
                                }
                                this.currentForm.domains[this.clickIndex].taskList = res.data;
                                this.currentForm = this.currentForm;
                                // this.$forceUpdate();
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.clear();});
                }
            },
            //添加工时
            addNewWorktime(index, item) {
                item.worktimeList.push({});
            },

            // 添加项目 
            addNewPro() {
                var leftProgress = 10;
                if (this.reportTimeType.type == 3) {
                    //计算已经待分配工时比例
                    let array = this.currentForm.domains;
                    let totalProgress = 0;
                    for (var i=0;i<array.length; i++) {
                        totalProgress += array[i].progress;
                    }
                    if (totalProgress < 100) {
                        leftProgress = 100 - totalProgress;
                    }
                }

                this.currentForm.domains.push({
                    id: null,
                    projectId: "",
                    projectName: "",
                    workingTime: this.reportTimeType.type==3?(leftProgress*this.reportTimeType.allday/100).toFixed(1): 4.0,
                    progress:leftProgress,
                    content: "",
                    state: 2,
                    isOvertime:false,
                    multiWorktime: this.reportTimeType.multiWorktime,
                    worktimeList:[{}],
                    degreeId: null
                })
            },

            // 移除项目
            delPro(i) {
                this.$dialog.confirm({
                    title: '',
                    message: '是否移除当前项目'
                }).then(() => {
                    this.currentForm.domains.splice(i,1);
                }).catch(() => {
                });
            },
            // 判断时间段
            fns() {
                for (let k in this.dateAr) {
                    if (!this.judege(k)) {
                    return false
                    }
                }
                return true
            },
            judege(idx){
                for (let k in this.dateAr) {
                    if (idx !== k) {
                        if (this.dateAr[k].s <= this.dateAr[idx].s && this.dateAr[k].e > this.dateAr[idx].s) {
                        return false
                        }
                        if (this.dateAr[k].s < this.dateAr[idx].e && this.dateAr[k].e >= this.dateAr[idx].e) {
                        return false
                        }
                    }
                }
                return true
            },
            // 清空当前填写信息
            submitClear(){
                if(this.inbtn == 999) {
                    this.summary = ''
                    return
                }
                this.currentForm.domains = [{
                                id: null,
                                projectId: "",
                                projectName: "",
                                workingTime: this.user.timeType.allday,
                                content: "",
                                state: 2,
                                multiWorktime:0,
                                worktimeList:[{}],
                                degreeId: ''
                            }]
                // console.log(this.currentForm);
            },
            checkDateWorkTime() {
                var array = [];
                for(let m in this.form){
                    let allhours = 0
                    for(let n in this.form[m].domains){
                        if(this.form[m].domains[n].projectId){
                            allhours += this.form[m].domains[n].workingTime
                        }
                    }
                    if (allhours) {
                        var workData = {date: this.form[m].createDate, workingTime: allhours};
                        array.push(workData);
                    }
                }
                if (array.length > 0) {
                    this.$axios.post("/report/checkBeiSengCardTime", { userId:this.user.id, dateWorkingTime:JSON.stringify(array)})
                        .then(res => {
                            this.flgLg = true
                            if (res.code == "ok") {
                                this.submitReport(0)
                            } else {
                                this.$dialog.confirm({
                                    title: '提示',
                                    message: res.msg + '，是否忽略并提交?'
                                }).then(() => {
                                    this.submitReport(0)
                                }).catch(() => { })
                            }
                        }).catch(err => { this.$toast.clear(); });
                } else {
                    submitReport(0);
                }
            },
            // 提交日报
            submitReport(isDraft) {
                if(this.inbtn != 999) {
                    this.form[this.inbtn] = this.currentForm
                }
                console.log(this.form)
                console.log(this.currentForm)
                // return
                const { allday, workContentState, enableNewWeeklyfill, reportAuditType } = this.user.timeType // 系统设置的每日工作时间
                // 针对物奇做的判断
                if(this.user.timeType.enableNewWeeklyfill == 1){
                    const { next, error, group } = this.judgeStrange(this.form)
                    if(!next){
                        if(error.length > 0) {
                            this.$toast(`【${error.join('、')}】填写工时合计非 ${allday} 小时`)
                        } else {
                            this.$toast(`${group.join('、')} 请选择项目分组`)
                        }
                        
                        return
                    }
                }
                console.log(workContentState)
                // 判断工作事项是否为必填
                if(workContentState == '1') {
                    const { error, next } = this.judgeWorkContent(this.form)
                    if(!next) {
                        this.$toast(`【${error.join('、')}】请工作事项`)
                        return
                    }
                }

                // 判断 report_audit_type  = 1 和 2 的情况下任务分组填没填
                if(reportAuditType == 1 || reportAuditType == 2) {
                    const { error, next } = this.judgeWorkGroud(this.form)
                    if(!next) {
                        this.$toast(`【${error.join('、')}】请填写任务分组`)
                        return
                    }
                }

                //检查子项目
                if (this.user.timeType.subProMustFill == 1) {
                    for(let i in this.form){
                        for(let m in this.form[i].domains){
                            var oneProItem = this.form[i].domains[m]
                            if (oneProItem.subProjectList && oneProItem.subProjectList.length > 0 && !oneProItem.subProjectId) {
                                //没有选择子项目
                                this.$toast('子项目必填，请检查')
                                return
                            }
                        }
                    }
                }

                if(this.user.timeType.notAllowedNoAttendance == 1){
                    let toast = ''
                    for(let i in this.form){
                        let havework = false
                        for(let m in this.form[i].domains){
                            if(this.form[i].domains[m].projectId){
                                havework = true
                            }
                        }
                        if(havework){
                            if(!this.form[i].cardtime){
                                toast += this.weekArr[i] + '、'
                            }
                        }
                    }
                    if(toast){
                        toast = toast.substring(0,toast.length - 1)
                        this.$toast.fail('[' + toast +']无考勤记录不可填报')
                        return
                    }
                }
                //针对凡己，不校验考勤时长
                if(this.user.companyId != 3918 && this.user.companyId != 5978 && this.user.companyId != 4281 && (this.user.timeType.showDdCardtime == 1 || this.user.timeType.showCorpwxCardtime == 1) && this.reportTimeType.type == 1){
                    let tips = ''
                    for(let m in this.form){
                        let allhours = 0
                        for(let n in this.form[m].domains){
                            if(this.form[m].domains[n].projectId){
                                allhours += this.form[m].domains[n].workingTime
                            }
                        }
                        if(this.form[m].cardtime && this.form[m].cardtime < allhours){
                            tips += this.weekArr[m] + ','
                        }
                    }
                    if(tips){
                        tips = tips.substring(0,tips.length - 1)
                        this.$toast('['+tips+']填报工时之和不能超过考勤总工时')
                        return
                    }
                }
                let formData = new URLSearchParams()
                // 周总结
                if(enableNewWeeklyfill == 1) {
                    formData.append("summary", this.summary);
                }
                for(let formIndex=0;formIndex<this.form.length;formIndex++){
                    this.dateAr = []
                    let alp = []
                    if(this.user.timeType.multiWorktime == 1) {
                        for(var p in this.form[formIndex].domains) {
                            var slll = this.form[formIndex].domains[p]
                            for(var o in slll.worktimeList) {
                                if(slll.worktimeList[o].startTime || slll.worktimeList[o].endTime) {
                                    let objs = {}
                                    objs.s = slll.worktimeList[o].startTime
                                    objs.e = slll.worktimeList[o].endTime
                                    alp.push(objs)
                                }
                            }
                        }
                    }else{
                        for(let m in this.form[formIndex].domains){
                            let slll2 = this.form[formIndex].domains[m]
                            let objs = {}
                            objs.s = slll2.startTime
                            objs.e = slll2.endTime
                            alp.push(objs)
                        }
                        if(this.form[formIndex].reportTimes){
                            let timearr = this.form[formIndex].reportTimes.split(',')
                            for(let i in timearr){
                                let objs = {}
                                objs.s = timearr[i].split('-')[0]
                                objs.e = timearr[i].split('-')[1]
                                alp.push(objs)
                            }
                        }
                    }
                    this.dateAr = alp
                        if(this.dateAr.length > 0) {
                            let trus = this.fns()
                            if(!trus) {
                                this.$toast(this.weekArr[formIndex]+'填写的时间段重叠')
                                return
                            }
                        }

                    

                    if (this.reportTimeType.type == 0) {
                        var alldayNum = 0;
                        var amNum = 0;
                        var pmNum = 0;
                        for(var i in this.form[formIndex].domains) {
                            if (this.form[formIndex].domains[i].timeType == 0) {
                                alldayNum ++;
                            } else if (this.form[formIndex].domains[i].timeType == 1) {
                                amNum++;
                            } else if (this.form[formIndex].domains[i].timeType == 2) {
                                pmNum++;
                            }
                        }
                        if (alldayNum > 1) {
                            this.$toast.fail(this.weekArr[formIndex]+"的工作时间-全天，只能选择一次");
                            return;
                        }
                        if (amNum > 1) {
                            this.$toast.fail(this.weekArr[formIndex]+"的工作时间-上午，只能选择一次");
                            
                            return;
                        }
                        if (pmNum > 1) {
                            this.$toast.fail(this.weekArr[formIndex]+"的工作时间-下午，只能选择一次");
                            return;
                        }
                        if (alldayNum == 1 && (amNum > 0 || pmNum > 0)) {
                            this.$toast.fail(this.weekArr[formIndex]+"的工作时间-全天，不能和上下午同时存在");
                            return;
                        }
                    } else if (this.reportTimeType.type == 3) {
                        //总百分比不能超过100%
                        let total = 0;
                        this.form[formIndex].domains.forEach(w=>{total += w.progress});
                        if (total > 100) {
                            this.$toast.fail(this.weekArr[formIndex]+"的用时比例之和不能超过100%");
                            return;
                        } else if (total < 100) {
                            this.$toast.fail(this.weekArr[formIndex]+"的工时尚未完全分配，无法提交");
                            return;
                        }
                    }

                    // formData.append("draft", this.isDraft);
                    formData.append("draft", isDraft);
                    //填字段
                    for(var i in this.form[formIndex].domains) {
                        //没有选择项目的，跳过
                        if (!this.form[formIndex].domains[i].projectId) {
                            continue;
                        }


                        if(this.user.timeType.reportAuditType == 3){
                            let audItem = {}
                            let auditRule = ''
                            if(this.form[formIndex].domains[i].id){
                                audItem.reportId = this.form[formIndex].domains[i].id
                            }
                            if(this.user.timeType.auditLevel > 0){
                                if(!this.form[formIndex].domains[i].auditorFirst.id){ auditRule += '第一、' }
                                audItem.auditorFirst = this.form[formIndex].domains[i].auditorFirst.id
                            }
                            if(this.user.timeType.auditLevel > 1){
                                // if(!this.form[formIndex].domains[i].auditorSec.id){ auditRule += '第二、' }
                                audItem.auditorSec = this.form[formIndex].domains[i].auditorSec.id
                            }
                            if(this.user.timeType.auditLevel > 2){
                                // if(!this.form[formIndex].domains[i].auditorThird.id){ auditRule += '第三、' }
                                audItem.auditorThird = this.form[formIndex].domains[i].auditorThird.id
                            }
                            if(this.form[formIndex].domains[i].ccUserid.id){
                                audItem.ccUserid = this.form[formIndex].domains[i].ccUserid.id
                            }
                            if(auditRule){
                                auditRule = auditRule.substring(0,auditRule.length - 1)
                                this.$toast.fail('请指定' + this.weekArr[formIndex] + '的' + auditRule + '审核人')
                                return
                            }
                            formData.append("auditorSettingArray", JSON.stringify(audItem).replace(/,/g,"@"));
                        }

                        if(this.user.timeType.taskGroupRequired == 1 && !this.form[formIndex].domains[i].groupId){
                            this.$toast.fail('请指定' + this.weekArr[formIndex] + '的任务分组')
                            return
                        }


                        if (this.user.timeType.customDegreeActive == 1) {
                            let customDegreeMultiple = this.user.timeType.customDegreeMultiple
                            if(this.form[formIndex].domains[i].degreeId) {
                                // formData.append("degreeId", this.form[formIndex].domains[i].degreeId);
                                if(!customDegreeMultiple) { // 单选
                                    formData.append("degreeId", this.form[formIndex].domains[i].degreeId);
                                } else {
                                    console.log(this.form[formIndex].domains[i].degreeId)
                                    let newDegreeId = this.form[formIndex].domains[i].degreeId.split(',')
                                    formData.append("multiDegrId", JSON.stringify(newDegreeId).replace(/,/g, '@'))
                                }
                            } else {
                                if(this.user.timeType.customDegreeStatus == 1) {
                                    let times = this.form[formIndex].createDate
                                    console.log(times)
                                    this.$toast.fail(`[${this.getWeekDay(this.formatTime(times))}] 请选择 ${this.user.timeType.customDegreeName}`)
                                    return
                                }
                                formData.append("degreeId", !customDegreeMultiple ? -1 : "[]");
                            }
                        } else {
                            formData.append("degreeId", -1);
                        }
                        if(this.form[formIndex].domains[i].customData) {
                            formData.append("customData", this.form[formIndex].domains[i].customData);
                        } else {
                            formData.append("customData", "0");
                        }
                        if(this.form[formIndex].domains[i].customText) {
                            formData.append("customText", this.form[formIndex].domains[i].customText);
                        } else {
                            formData.append("customText", "-");
                        }
                        
                        if (this.form[formIndex].domains[i].id) {
                            formData.append("id", this.form[formIndex].domains[i].id);
                        } else {
                            formData.append("id", -1);
                        }
                        formData.append("projectId", parseFloat(this.form[formIndex].domains[i].projectId));
                        if (this.form[formIndex].domains[i].subProjectId) {
                            formData.append("subProjectId", this.form[formIndex].domains[i].subProjectId);
                        } else {
                            formData.append("subProjectId", 0);
                        }
                        if (this.form[formIndex].domains[i].groupId) {
                            formData.append("groupId", this.form[formIndex].domains[i].groupId);
                        } else {
                            formData.append("groupId", 0);
                        }
                        if (this.form[formIndex].domains[i].stage) {
                            formData.append("stage", this.form[formIndex].domains[i].stage);
                        } else {
                            formData.append("stage", '-');
                        }
                        formData.append("reportTimeType", this.reportTimeType.type);
                        formData.append("multiWorktime", this.reportTimeType.multiWorktime);
                        if (this.reportTimeType.type == 0) {
                            formData.append("timeType", this.form[formIndex].domains[i].timeType);
                            formData.append("workingTime", parseFloat(this.form[formIndex].domains[i].workingTime));
                        } else if (this.reportTimeType.type == 1) {
                            formData.append("workingTime", parseFloat(this.form[formIndex].domains[i].workingTime));
                        } else if (this.reportTimeType.type == 2) {
                            formData.append("startTime", this.form[formIndex].domains[i].startTime);
                            formData.append("endTime",this.form[formIndex].domains[i].endTime);
                        } else if (this.reportTimeType.type == 3) {
                            formData.append("progress", this.form[formIndex].domains[i].progress);
                            formData.append("workingTime",this.form[formIndex].domains[i].workingTime);
                        }
                        
                        if (this.form[formIndex].domains[i].taskId) {
                            formData.append("taskId", this.form[formIndex].domains[i].taskId);
                        } else {
                            formData.append("taskId", 0);
                        }
                        if (this.form[formIndex].domains[i].taskFinish == true) {
                            formData.append("taskFinish", 1);
                        } else {
                            formData.append("taskFinish", 0);
                        }
                        formData.append("createDate", this.form[formIndex].createDate);
                        formData.append("isOvertime", this.form[formIndex].domains[i].isOvertime?1:0);
                        if (this.form[formIndex].domains[i].overtimeHours && this.form[formIndex].domains[i].isOvertime == 1) {
                            formData.append("overtimeHours", this.form[formIndex].domains[i].overtimeHours);
                        } else {
                            formData.append("overtimeHours", 0);
                        }
                        if(this.form[formIndex].domains[i].basecostId){
                            formData.append("basecostId", this.form[formIndex].domains[i].basecostId);
                        }
                        


                        //项目专业进度
                        if (this.form[formIndex].domains[i].professionProgress) {
                            let m = JSON.stringify(this.form[formIndex].domains[i].professionProgress);
                            m = m.replace(/,/g,"@");//replaceAll(',','@');企业微信不兼容replaceAll
                            formData.append("professionProgress", m);
                        } else {
                            formData.append("professionProgress", "[]");
                        }
                        //图片的处理，serverPic是当前所有的照片
                        if (this.form[formIndex].domains[i].serverPics) {
                            let m = JSON.stringify(this.form[formIndex].domains[i].serverPics);
                            m = m.replace(/,/g,"@");//replaceAll(',','@');企业微信不兼容replaceAll
                            formData.append("pics", m);
                        } else {
                            formData.append("pics", "@");
                        }
                        
                        //处理多个时间事项
                        if (this.reportTimeType.multiWorktime == 1) {
                            //检查时间是否有重叠
                            var workList = this.form[formIndex].domains[i].worktimeList;
                            for (var j=0;j<workList.length; j++) {
                                var curItem = workList[j];
                                if (curItem.startTime == null || curItem.endTime == null) {
                                    this.$toast.fail("请设置"+this.weekArr[formIndex]+"的工作时间");
                                    return;
                                }
                                //检查开始时间是否大于结束时间
                                if (curItem.startTime >= curItem.endTime) {
                                    this.$toast.fail(this.weekArr[formIndex]+"的时间段"+curItem.startTime+'-'+curItem.endTime+"有误："+ 
                                            "结束时间必须大于开始时间");
                                    return;
                                }
                                for (var p = j+1;p<workList.length; p++) {
                                    var jItem = workList[p];
                                    if ((jItem.startTime>=curItem.startTime&&jItem.startTime < curItem.endTime)
                                            || (jItem.endTime>curItem.startTime&&jItem.endTime <= curItem.endTime)) {
                                        
                                        this.$toast.fail(this.weekArr[formIndex]+"的时间段"+curItem.startTime+'-'+curItem.endTime+"与"+ 
                                                jItem.startTime+'-'+jItem.endTime+ "存在重叠，请修改。");
                                        return;
                                    }
                                }
                            }

                            let m = JSON.stringify(this.form[formIndex].domains[i].worktimeList);
                            m = m.replace(/,/g,"@");//replaceAll(',','@');企业微信不兼容replaceAll
                            formData.append("content", m);
                        } else {
                            if (this.form[formIndex].domains[i].content) {
                                formData.append("content", this.form[formIndex].domains[i].content);
                            } else {
                                formData.append("content", '-');
                            }
                        }

                        //审核人
                        if (this.form[formIndex].domains[i].projectAuditorId) {
                            formData.append("projectAuditorId", this.form[formIndex].domains[i].projectAuditorId);
                        } else if(this.user.timeType.reportAuditType == 0){
                            this.$toast.fail('请选择'+this.weekArr[formIndex]+'投入项目的审核人');
                            return;
                        }
                    }

                }
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });

                this.$axios.post("/report/editReport", formData)
                .then(res => {
                    // this.flgLg = true
                    if(res.code == "ok") {
                        this.$toast.clear();
                        if(res.msg!=null){
                            this.$dialog.alert({
                                message: res.msg,
                            }).then(() => {
                                // on close
                                this.$router.push("/index");
                            });
                        }else{
                            this.$toast.success(this.isDraft==0?'提交成功':'暂存成功');
                            setTimeout(()=> {
                                this.$router.push("/index");
                                this.summary = ''
                            }, 1000);
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail((this.isDraft==0?'提交失败':'暂存失败')+'：'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear(),console.log(err, '异常')});
            },
            //初始化参数
            initWxConfig() {
                var curUrl=location.href.split("#")[0];
                
                this.$axios.post("/wxcorp/getCorpWXConfig", {url: curUrl, token: this.user.id})
                        .then(res => {
                            if(res.code == "ok") {
                                var data=res.data;
                                wx.config({ 
                                beta: true,
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: data.appid, // 必填，公众号的唯一标识 
                                timestamp: data.timestamp, // 必填，生成签名的时间戳 
                                nonceStr: data.noncestr, // 必填，生成签名的随机串 
                                signature: data.sign, // 必填，签名，见附录1 
                                jsApiList: [
                                    'chooseImage',
                                    'previewImage',
                                    'uploadImage',
                                    'downloadImage',
                                    'previewFile',
                                    'getLocation',
                                ] 
                                });
                                var that = this;
                                wx.ready(function(){
                                    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                                    // if (that.user.companyId == 7) {
                                    //     that.$toast('wx init success==7 ');
                                    //     that.getLocation();
                                    // }
                                });
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.fail('发生异常'+err);console.log(err)});
                
            },
            deleteImg(item, index) {
                //删除添加的图片
                if (this.isIOSystem) {
                    item.iospics.splice(index,1);
                    item.iospics = item.iospics;
                }
                item.pics.splice(index,1);
                item.pics = item.pics;

                //传递到后台的数据也要做同步删除
                item.serverPics.splice(index,1);
                this.$forceUpdate();
            },
            //企业微信获取定位
            getLocation() {
                wx.getLocation({
                    type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                        var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                        alert(JSON.stringify(res));
                    }
                });
            },

            // 针对物奇定制私人按周填报写的方法
            async getProjectGroup(domainItem, index) {
                const { data } = await this.$axios.post("/task-group/listProjectGroupAndAuditor", {projectId: domainItem.projectId})
                if(!data) {
                    return
                }
                if(data.length == 0) {
                    this.$toast.fail('分组未设置，请联系该项目管理人员');
                }
                if(data.length == 1) {
                    domainItem.groupId = data[0].id;
                    domainItem.groupName = data[0].name;
                    domainItem.projectAuditorId = data[0].inchargerId;
                    domainItem.projectAuditorName = data[0].inchargerName;
                    if (!data[0].inchargerId) {
                        this.$toast.fail(`【${data[0].name}】分组未设置审批人，请联系该项目管理人员`);
                    }
                }
                const newArr = data.map(item => {
                    const { inchargerId, inchargerName } = item;
                    return {
                        auditorId: inchargerId,
                        auditorName: inchargerName
                    };
                });

                domainItem.taskGroups = data;
                domainItem.auditUserList = newArr;
                this.$forceUpdate();
            },
            // 提交数据物奇的判断
            judgeStrange(from) {
                console.log(from, '<======== 要判断的fom表单')
                const newFrom = JSON.parse(JSON.stringify(from))
                const arrList = Object.values(newFrom).flatMap(({ domains, createDate }) =>
                    domains.map(({ workingTime, projectId, projectName, groupId }) => ({
                        dateTime: createDate,
                        workingTime,
                        projectId,
                        projectName,
                        groupId,
                        weeks: this.getNewWeek(createDate)
                    }))
                );
                console.log(arrList, '<==== 判断的数据')
                let { strArr, groupArr } = this.judgmentData(arrList)
                let strDataArr = this.getWeek(strArr);
                let strGroupArr = groupArr

                if(strDataArr.length > 0 || strGroupArr.length > 0) {
                    return {
                        next: false,
                        error: [...new Set(strDataArr)],
                        group: [...new Set(strGroupArr)]
                    }
                } else {
                    return {
                        next: true,
                        error: [],
                        group: []
                    }
                }
            },
            // 提交数据，工作事项的判断
            judgeWorkContent(from) {
                console.log(from, '<======== 要判断的fom表单')
                const newFrom = JSON.parse(JSON.stringify(from))
                const arrList = []
                for(var i in newFrom) {
                    const { domains, createDate } = newFrom[i]
                    for(var j in domains) {
                        if(domains[j].projectId && !domains[j].content) {
                            arrList.push({
                                dateTime: createDate,
                                projectName: domains[j].projectName,
                                weeks: this.getNewWeek(createDate)
                            })
                        }
                    }
                }
                console.log(arrList.map(item => `【${item.dateTime} ${item.weeks} 中的 （${item.projectName}）项目】`), '查看对象')
                if(arrList.length > 0) {
                    return {
                        next: false,
                        error: arrList.map(item => `【${item.dateTime} ${item.weeks} 中的 （${item.projectName}）项目】`),
                    }
                } else {
                    return {
                        next: true,
                        error: [],
                    }
                }
            },
            // 判断任务分组是否填写
            judgeWorkGroud(from) {
                const newFrom = JSON.parse(JSON.stringify(from))
                const arrList = []
                for(var i in newFrom) {
                    const { domains, createDate } = newFrom[i]
                    for(var j in domains) {
                        if(domains[j].projectId && !domains[j].groupId) {
                            arrList.push({
                                dateTime: createDate,
                                projectName: domains[j].projectName,
                                weeks: this.getNewWeek(createDate)
                            })
                        }
                    }
                }
                if(arrList.length > 0) {
                    return {
                        next: false,
                        error: arrList.map(item => `【${item.dateTime} ${item.weeks} 中的 （${item.projectName}）项目】`),
                    }
                } else {
                    return {
                        next: true,
                        error: [],
                    }
                }
            },
            // 判断是否小于系统设置的每日工作时间
            judgmentData(data) {
                const { allday } = this.user.timeType // 系统设置的每日工作时间
                const result = [];
                data.forEach((item) => {
                    const date = item.dateTime;
                    const index = result.findIndex((arr) => arr[0].dateTime === date);

                    if (index === -1) {
                        result.push([item]);
                    } else {
                        result[index].push(item);
                    }
                });

                const strArr = result
                    .filter((arr) => arr[0].projectId)
                    .filter(
                        (arr) => arr.reduce((sum, item) => sum + (+item.workingTime || 0), 0) != allday
                    )
                    .map((arr) => arr[0].dateTime);

                const groupArr = data
                    .filter(item => item.projectId && !item.groupId)
                    .map(({ dateTime, weeks, projectName }) => `【${dateTime} ${weeks} 中的 （${projectName}）项目】`);

                return { strArr, groupArr }
            },
            // YYYY-MM-DD 转成 周几
            getWeek(arr) {
                const weekArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                for (let i = 0; i < arr.length; i++) {
                    const date = new Date(arr[i]);
                    const week = weekArr[date.getDay()];
                    arr[i] = `${arr[i]} ${week}`;
                }
                return arr;
            },
            getNewWeek(data) {
                const weekArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                const date = new Date(data);
                return weekArr[date.getDay()];
            },
            // 重新定义获取接口
            async newPost(url, param) {
                return new Promise((resolve, reject) => {
                    this.$axios.post(url, param,
                        res => {
                            resolve(res)
                        },
                        error => {
                            this.$toast.fail(error);
                            reject(error)
                        }
                    )
                });
            },
            weekText(item) {
                return this.getWeekDay(item)
            },
            // 中国标准时间转成周几
            getWeekDay(date) {
                var week = new Date(date).getDay();
                var weekArr = ['日', '一', '二', '三', '四', '五', '六'];
                return '周' + weekArr[week];
            },
            // 中国标准时间转成YYYY-MM-DD
            formatDate(date) {
                if (!date) {
                    return '';
                }
                const newDate = new Date(date);
                const year = newDate.getFullYear();
                const month = newDate.getMonth() + 1;
                const day = newDate.getDate();
                return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
            },
            // 将 YYYY-MM-DD 转成 中国标准时间
            formatTime(date) {
                if (!date) {
                    return '';
                }
                const newDate = new Date(date);
                const year = newDate.getFullYear();
                const month = newDate.getMonth() + 1;
                const day = newDate.getDate();
                return `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;
            },

            // 获取按周填报的数据
            async getWeeklyReportData() {
                if(!this.temporaryStorage) {
                    return
                }
                const { data } = await this.$axios.post("/report/getWeeklyReportData", { targetDate: this.dateText[0] })
                const { dateList } = data
                this.form =  this.handleWeeklyReportData(dateList)
                this.currentForm = this.form[this.inbtn]
                this.getEachArray(this.currentForm)
            },
            // 处理按周填报回显数据
            handleWeeklyReportData(array) {
                console.log(array, 'array')
                const { timeType } = this.user
                let objectItem = {
                    auditorFirst: {id: '', name: ''},
                    auditorSec: {id: '', name: ''},
                    auditorThird: {id: '', name: ''},
                    ccUserid: {id: '', name: ''},
                    content: '',
                    degreeId: '',
                    id: null,
                    multiWorktime: 0,
                    progress: 100,
                    projectId: '',
                    projectName: '',
                    state: 2,
                    workingTime: 8,
                    worktimeList: []
                }

                let dataList = []
                for(var i in array) {
                    const arrayItem = array[i]
                    let assignoBject = {
                        createDate: arrayItem.date
                    }
                    if(arrayItem.reportList.length > 0) {
                        let list = []
                        for(var j in arrayItem.reportList) {
                            const { 
                                id,
                                endTime, startTime, content, groupId, groupName,
                                projectAuditorId, projectAuditorName, projectId,
                                projectName, state, taskId, taskName, overtimeHours,
                                stage, taskGroups, isOvertime, workingTime, subProjectId, progress,
                                multiDegrId, degreeId, degreeList
                            } = arrayItem.reportList[j]

                            let newMultiDegrId = []
                            let newWeiduName = (degreeList || []).filter(s => s.id == degreeId).map(s => s.name).join(',')
                            if(timeType.customDegreeMultiple) {
                                newMultiDegrId = multiDegrId ? JSON.parse(multiDegrId.replace(/@/g, ',')) : [] 
                                newMultiDegrId = newMultiDegrId.map(item => {
                                    return parseInt(item, 10);
                                })

                                newWeiduName = (degreeList || []).filter(s => newMultiDegrId.includes(+s.id)).map(s => s.name).join(',');
                            }

                            let newObj = {
                                ...objectItem, id,
                                endTime, startTime, content, groupId, groupName,
                                projectAuditorId, projectAuditorName, projectId,
                                projectName, state, taskId, taskName, overtimeHours,
                                stage, taskGroups, workingTime, subProjectId, progress,
                                isOvertime: isOvertime == 1 ? true : false,
                                degreeId: !timeType.customDegreeMultiple ? degreeId : newMultiDegrId.join(','),
                                radioPickDegree: !timeType.customDegreeMultiple ? degreeId : newMultiDegrId,
                                wuduList: degreeList, weiduName: newWeiduName
                            }
                            list.push(newObj)
                        }
                        assignoBject.domains = JSON.parse(JSON.stringify(list))
                    } else {
                        assignoBject.domains = [JSON.parse(JSON.stringify(objectItem))]
                    }
                    dataList.push(assignoBject)
                }

                return dataList
            },
            // 获取回显数据的的各个数组（任务分组，阶段等）
            getEachArray(arrItem) {
                if(!this.temporaryStorage) {
                    return
                }
                console.log(arrItem, '<=== 处理的数据')
                const { timeType, company } = this.user
                let newArrItem = JSON.parse(JSON.stringify(arrItem))
                const { domains } = newArrItem
                for(let i in domains) {
                    const { projectId, projectName, stage, groupId, groupName, degreeId } = domains[i]
                    if(projectId) {
                        this.fZr({ projectId, projectName }, 0, true, i)
                        // if(timeType.customDegreeActive == 1) {
                        //     this.dimension(projectId, 0, true, timeType.customDegreeMultiple, i)
                        // }
                    }   

                    if(groupId) {
                        this.choseTaskGroup({ id: groupId, name: groupName }, 0, true, i)
                    }

                    if(company.packageProject == 1 && company.nonProjectSimple==0) {
                        this.getTaskList(projectId, 0, true, i)
                    }
                }
            }
        },
        
        mounted() {
            const { companyId } = this.user
            this.temporaryStorage = [4374, 10].includes(companyId)
            console.log(this.temporaryStorage, '暂存权限', companyId)

            this.getUrlKey('date')
            const { enableNewWeeklyfill } = this.user.timeType // 针对物奇
            // if(enableNewWeeklyfill == 1) {
            //     const urldate = this.getUrlKey('date')
            //     if(urldate) {
            //         this.nowTime = this.formatTime(urldate)
            //         console.log(this.nowTime, '时间')
            //     }
            // }
            this.timeRange = []
            let i = 0.0; 
            while(true) {
                if (i < 24) {
                    //校验上下限
                    if (i >= this.user.timeType.minReportTime && i <= this.user.timeType.maxReportTime) {
                        this.timeRange.push(i);
                    }
                    i += 0.5;
                } else {
                    break;
                }
            }
            this.getDateRange()
            var ua = navigator.userAgent.toLowerCase();
            this.isIOSystem = this.isIOS();
            if (ua.indexOf("wxwork") > 0) {
                this.isCorpWX = true;
            } else if (ua.indexOf("micromessenger") > 0) {
                this.isWX = true;
            }
            this.getPeoject() // 获取项目
            //获取传递过来的日期
            // var passDate = this.$route.query.date;
            // if (passDate != null) {
            //     this.form.createDate = this.$route.query.date;
            // }
            
            this.getProject();
            this.getReportBasecostList()
            this.getAllUsersList()
            // this.getReport();
            this.getTimeType();
            //初始化微信js-sdk参数
            if (this.isCorpWX) {
                this.initWxConfig();
            }

            // if(localStorage.getItem("formVal")) {
            //     var obj = localStorage.getItem("formVal")
            //     this.form = JSON.parse(obj)
            //     localStorage.removeItem('formVal')
            // }

            // 获取按周填报的数据
            this.getWeeklyReportData()
        },
        beforeDestroy() {
            // localStorage.removeItem('formVal')
        }
    };
</script>

<style lang="less" scope>
.groupingSelection {
    height: 100%;
    overflow-y: auto;
    padding: 10px 0;
    .groupingSelectionItem { 
        padding: 15px 15px;
        border-bottom: 1px solid #c2c2c2;
    }
}
.chooseSomeone {
    height: 80vh;
    display: flex;
    flex-direction: column;
    .chooseSomeoneFlex1 {
        flex: 1;
        overflow: auto;
    }
    .chooseSomeoneBtn {
        width:100%;
    }
    .chooseSomeoneItem {
        padding: 10px 15px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid #f6f6f6;
    }
}
.currentState {
    position: absolute;
    top: 15px;
    left: 112px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;

}
    .van-field__control:disabled {
        -webkit-text-fill-color:#646566;
    }
    .login_form {
        margin-top: 46px;
    }

    .form_domains {
        position: relative;
        .form_copy {
            position: absolute;
            right: 85px;
            top: 10px;
        }
        .form_addNew {
            text-align: center;
            margin:10px;
        }
        .form_del {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        // .form_del {
        //     color: #ff0000;
        //     font-size: 22px;
        //     position: absolute;
        //     right: 15px;
        //     top: 10px;
        // }
    }

    .form_tip {
        text-align: center;
        margin-top: 20px;
        color: #afafaf;
        font-size: 14px;
    }

    .card__footer {
        padding-top: 10px;
    }
    .card__tags {
        .van-tag {
            margin-right: 5px;
        }
    }
    .overTimeClas{
        display: flex;
        width: 7.4rem;
        // justify-content: space-between;
        align-items: center;
    }
    .inbtn{
        .selectgxbtn{
            color: #1989fa;
            border-color: #1989fa;
        }
    }
    .selectgxbtn{
        width: 100%;
    }
</style>
<style lang="less">
    
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
    .overtime {
        height: 50px;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        align-items: center;
    }
    .custom-button {
    width: 40px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    background-color: #1989fa;
    border-radius: 100px;
  }
  .imgList {
    position: relative;
    width: 100px;
    height: 100px;
    display: inline-block;
  }
  .imgList_i {
      position: absolute;
      top: -8px;
      right: -6px;
      z-index: 999; 
  }
  .ryuan {
       padding: 0.3rem 0.53333rem;
        // padding: 0 20px;
        // line-height: 40px;
        // height: 40px;
        color: #333;
        border-bottom: 2px solid #f6f6f6;
        width: 100%;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .overListTime {
        margin-left:5px;
        display: inline-block;
        width: 2rem;
        color: #646566;
    }
    .hoveOver {
        color: #C8C9CC;
    }
    .van-checkbox__label {
        color: #646566 !important;
    }
    .van-checkbox__label--disabled {
        color: #c8c9cc !important;
    }
    .submitClear{
        position: absolute;
        right: 0.1rem;
        bottom: -0.95rem;
        z-index: 1;
    }
    .kaoqin{
        width: 100%;
        padding: 0.1rem 0;
        background-color: #fff;
        color: #969799;
        display: flex;
        align-items: center;
        font-size: 0.35rem;
        line-height: 0.64rem;
    }
    .kaoqin2{
        width: 100%;
        padding: 0.1rem;
        background-color: #fff;
        color: #969799;
        display: flex;
        align-items: center;
        font-size: 0.35rem;
        line-height: 0.64rem;
    }
    .kaoqin span{
        width: 50%;
        text-align: center;
    }
</style>

